import * as React from "react";
import { useDataProvider, useListContext, useRedirect } from "ra-core";
import { Button, CreateButton, Datagrid, EditButton, FunctionField, List, Loading, ShowButton, TextField, useNotify, useRefresh } from "react-admin";
import { cellContentCentered, dataGrid, iconAction, listRoot, margin1, textColor, topActionButtonBaseStyle, width } from '../Styles'
import { ActionTitles, RESOURCES, RESOURCES_BASEPATH } from "../Constants";
import ListActions from "../common/ListActions";
import { CustomPagination } from "../common/CustomPaginationComponent";
import { Box, Typography } from "@mui/material";
import cloneDeep from 'lodash/cloneDeep';
import defaultsDeep from 'lodash/defaultsDeep';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CustomTooltip from "../../utils/CustomTooltip";
import CustomDeleteWithConfirmButton from "../../utils/CustomDeleteWithConfirmButton";
import { MattersFilter } from "./MattersFilter";
import { isAdminUser, showFileRoute } from "../DataProvider";
import { isUndefined } from "lodash";
import { Error404 } from "../errors/Error404";

export const Matters = () => {
    const isAdmin = isAdminUser();
    const dataProvider = useDataProvider();
    const [clientId, setClientId] = React.useState(undefined);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState();

    React.useEffect(() => {
        if (!isAdmin) {
            dataProvider
                .getMappedUsersSummary(`client/mapped-users`)
                .then(({ data }) => {
                    setData(data);
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProvider]);

    React.useEffect(() => {
        if (isAdmin && !isUndefined(clientId)) {
            setLoading(true);
            dataProvider
                .getMappedUsersbyClientId({ clientId: clientId })
                .then(({ data }) => {
                    setData(data);
                })
                .catch((error) => {
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [clientId, dataProvider, isAdmin]);

    if (loading && !isAdmin) return <Loading />;
    if (error) return <Error404 />;

    return (
        <List
            sx={listRoot}
            actions={
                <ListActions
                    title={ActionTitles.MATTERS}
                    basePath={RESOURCES_BASEPATH.MATTERS}
                    showCreateButton={true}
                    recordName={ActionTitles.MATTER}
                    filters={<MattersFilter userNames={data} isAdmin={isAdmin} />}
                />
            }
            sort={{ field: 'lastModified', order: 'DESC' }}
            pagination={<CustomPagination />}
            empty={!isAdmin && <EmptyMatter />}
        >
            <MattersGrid data={data} isAdmin={isAdmin} clientId={clientId} setClientId={setClientId} />
        </List>
    );
}


const MattersGrid = (props) => {
    const { data, isAdmin, clientId, setClientId } = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [disableDelete, setDisableDelete] = React.useState(false);
    const enableFileRoute = showFileRoute();
    const list = useListContext();

    React.useEffect(() => {
        setClientId(list.filterValues.clientId);
        // eslint-disable-next-line
    }, [list.filterValues.clientId])

    const onDeleteSuccess = () => {
        notify('Matter Deleted', { type: "info", autoHideDuration: 1000 });
        setDisableDelete(false);
        refresh();
    };

    const onDeleteFailure = () => {
        notify("Unable to delete the Matter", { type: 'warning', autoHideDuration: 1000 });
        setDisableDelete(false);
        refresh();
    };

    return (
        <Datagrid
            sx={
                defaultsDeep(
                    cloneDeep(dataGrid.base),
                    cloneDeep(dataGrid.firstColSticky)
                )
            }
            bulkActionButtons={false}
        >
            <TextField
                source="name"
                label="NAME"
                sortable={false}
            />
            <FunctionField
                sx={cellContentCentered}
                source="accountsCount"
                render={(record) => {
                    return (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {record.accountsCount > 0 &&
                                <span style={cellContentCentered}>
                                    <p style={{ marginLeft: "0.25rem" }}>
                                        {record.accountsCount}
                                    </p>
                                    <p style={{ marginLeft: "0.25rem", ...margin1.right }}>
                                        <CheckCircleIcon style={{ ...textColor.success, ...cellContentCentered }} />
                                    </p>
                                </span>
                            }
                            {record.accountsWithIntegrityIssuesCount > 0 &&
                                <span style={cellContentCentered}>
                                    <p style={{ marginRight: "0.25rem", ...margin1.left }}>
                                        {record.accountsWithIntegrityIssuesCount}
                                    </p>
                                    <p style={{ marginRight: "0.25rem" }}>
                                        <WarningAmberIcon style={{ ...textColor.error, ...cellContentCentered }} />
                                    </p>
                                </span>
                            }
                            {record.accountsCount === 0 && record.accountsWithIntegrityIssuesCount === 0 && 0}
                        </div>
                    );
                }}
                label="ACCOUNTS"
                sortable={false}
            />
            <TextField
                source="lastModified"
                label="LAST MODIFIED"
                sortable={true}
            />
            <FunctionField
                render={(record) => (!isAdmin || clientId) && data.size > 0 && record.lastModifiedBy && data.get(record.lastModifiedBy) !== null ? data.get(record.lastModifiedBy) : ' '}
                label="MODIFIED BY"
                sortable={false}
            />
            <TextField
                sx={cellContentCentered}
                source="reportsCount"
                label="REPORTS GENERATED"
                sortable={false}
            />
            {enableFileRoute &&
                <FunctionField
                    sx={cellContentCentered}
                    source="uploadFiles"
                    label={false}
                    sortable={false}
                    render={(record) => (
                        <CustomTooltip title="Upload Statements/Files" placement="top">
                            <Button
                                sx={iconAction}
                                size="medium"
                                label=""
                                onClick={() => redirect(RESOURCES_BASEPATH.MATTERS + "/" + record.matterId + "/file")}
                            >
                                <FileUploadIcon />
                            </Button>
                        </CustomTooltip>
                    )}
                />}
            <FunctionField
                sx={cellContentCentered}
                source="View"
                label={false}
                sortable={false}
                render={(record) => (
                    <CustomTooltip title="View" placement="top">
                        <ShowButton
                            icon={<RemoveRedEyeOutlinedIcon />}
                            sx={iconAction}
                            size="medium"
                            label={""}
                            basePath={RESOURCES_BASEPATH.MATTERS}
                            record={record}
                        ></ShowButton>
                    </CustomTooltip>
                )}
            />
            <FunctionField
                sx={cellContentCentered}
                source="Edit"
                label={false}
                sortable={false}
                render={(record) => (
                    <CustomTooltip title="Edit" placement="top">
                        <EditButton
                            icon={<EditOutlinedIcon />}
                            sx={iconAction}
                            size="medium"
                            label={""}
                            disabled={isAdmin}
                            basePath={RESOURCES_BASEPATH.MATTERS}
                            record={record}
                        ></EditButton>
                    </CustomTooltip>
                )}
            />
            <FunctionField
                sx={cellContentCentered}
                source="Delete"
                label={false}
                sortable={false}
                render={(record) => (
                    <CustomTooltip title="Delete" placement="top">
                        <CustomDeleteWithConfirmButton
                            icon={<DeleteOutlinedIcon />}
                            sx={iconAction}
                            size="medium"
                            label={""}
                            disabled={isAdmin}
                            record={record}
                            recordName={ActionTitles.MATTER}
                            mutationMode="pessimistic"
                            resource={RESOURCES.MATTERS}
                            onSuccess={onDeleteSuccess}
                            onFailure={onDeleteFailure}
                            disableDelete={disableDelete}
                            setDisableDelete={setDisableDelete}
                        />
                    </CustomTooltip>
                )}
            />
        </Datagrid>
    );
}

const EmptyMatter = () => {
    const { basePath } = useListContext();

    return (
        <Box textAlign="center" sx={{ ...width.fluid.w100, paddingTop: "50px" }}>
            <Typography variant="h5" paragraph>
                No Matter present for Client
            </Typography>
            <CreateButton
                sx={{ ...topActionButtonBaseStyle() }}
                basePath={basePath}
                icon={<AddCircleOutlineIcon />}
                size="small"
                label={ActionTitles.CREATE_MATTER}
            />
        </Box>
    );
};