import * as React from "react";
import {
  List,
  TextField,
  EmailField,
  FunctionField,
  EditButton,
  ShowButton,
  BooleanField,
  useNotify,
  useRefresh,
  DatagridConfigurable,
  isEmpty,
  SelectField,
} from "react-admin";
import ListActions from "../common/ListActions";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ColorField from "../common/ColorField";
import { CustomLogoField } from "../common/CustomLogoField";
import { ActionTitles, CLIENT_STATUS_NAME, ENABLED_FEATURES, REPORT_BRANDING_TYPES, REPORT_BRANDING_TYPES_DISPLAY_NAMES, RESOURCES_BASEPATH,
  ENABLED_FEATURES_ENUM,
  IDENTITY_PERMISSION_CHOICES
 } from "../Constants";
import CustomTooltip from "../../utils/CustomTooltip";
import CustomDeleteWithConfirmButton from "../../utils/CustomDeleteWithConfirmButton";
import { trueCircleIcon, falseCircleIcon } from "../common/StyledIcons";
import { CustomPagination } from "../common/CustomPaginationComponent";
import {textDecoration,cellContentCentered,dataGrid,iconAction,listRoot,textColor,cursor} from '../Styles'
import defaultsDeep from 'lodash/defaultsDeep';
import cloneDeep from 'lodash/cloneDeep';
import {CustomDataGridHeader, onToggleDataGridHeader} from '../common/CustomDataGridHeader'
import {useState} from 'react'
import {CorporatePartnersFilter} from './CorporatePartnersFilters'
import Tooltip from '@mui/material/Tooltip'

export const CorporatePartners = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [disableDelete, setDisableDelete] = React.useState(false);
  let dataGridsLS = JSON.parse(localStorage.getItem("dataGrids"))
  if (!dataGridsLS)
    dataGridsLS = { "corporatePartners": true }
  const [dataGrids, setDataGrids] = useState(dataGridsLS ? dataGridsLS : {});

  const onDeleteSuccess = () => {
    setDisableDelete(false);
    notify('Corporate Partner Deleted', { type: 'info', autoHideDuration: 1000 });
    refresh();
  };

  const onDeleteFailure = () => {
    setDisableDelete(false);
    notify("Unable to delete the corporate partner", { type: 'warning', autoHideDuration: 1000 });
    refresh();
  };

  return (
    <List
      sx={listRoot}
      sort={{ field: "lastModified", order: "DESC" }}
      filterDefaultValues={{ searchKey: "clientName" }}
      actions={
        <ListActions
          title={ActionTitles.CORPORATE_PARTNERS}
          showCreateButton={true}
          showColumnsButton={true}
          basePath={RESOURCES_BASEPATH.CORPORATE_PARTNERS}
          filters={<CorporatePartnersFilter />}
        />
      }
      pagination={<CustomPagination />}   
      exporter={false}
    >
      <DatagridConfigurable
        header={
          <CustomDataGridHeader
            fixedColumn={dataGrids['corporatePartners']}
            onToggle={() => onToggleDataGridHeader('corporatePartners', dataGrids, setDataGrids)}
          />
        }
        sx={
          defaultsDeep(
            cloneDeep(dataGrid.base),
            dataGrids['corporatePartners'] ? cloneDeep(dataGrid.firstColSticky) : {},
          )
        }
        bulkActionButtons={false}
      >
        <TextField source="name" label="Name" />
        <EmailField sx={{...textColor.primary, ...textDecoration.none}}
          source="primaryUser"
          label="Primary User"
        />
        <CustomLogoField source="logo" label="Logo" sortable={false} />
        <ColorField source="primaryColour" label="Primary Colour" />
        <ColorField source="secondaryColour" label="Secondary Colour" />
        <ColorField source="tertiaryColour" label="Tertiary Colour" />
        <FunctionField
          source="reportBranding"
          label="Report Branding"
          sx={cellContentCentered}
          render={(record) => {            
            return (isEmpty(record.reportBranding) ? (REPORT_BRANDING_TYPES.DEFAULT) : (REPORT_BRANDING_TYPES_DISPLAY_NAMES[record.reportBranding]));
          }}
          sortable={false}
        />
        <BooleanField
          source="reportScheduling"
          label="Report Scheduling"
          sx={cellContentCentered}
          TrueIcon={trueCircleIcon}
          FalseIcon={falseCircleIcon}
          sortable={false}
        />                
        <TextField source="surveySupportType" label="Survey Support Type" />
        <BooleanField
          source="incomeInsightsPermission"
          label="Income Insights Permission"
          sx={cellContentCentered}
          TrueIcon={trueCircleIcon}
          FalseIcon={falseCircleIcon}
          sortable={false}
        />        
        <BooleanField
          source="riskInsightsPermission"
          label="Risk Insights Permission"
          sx={cellContentCentered}
          TrueIcon={trueCircleIcon}
          FalseIcon={falseCircleIcon}
          sortable={false}
        />
        {ENABLED_FEATURES.filter((enabledFeature) => enabledFeature.name !== ENABLED_FEATURES_ENUM.IDENTITY_VIEW_ONLY 
                    && enabledFeature.name !== ENABLED_FEATURES_ENUM.IDENTITY_ENABLED).map((entry) => (
          <BooleanField
            source={entry.name}
            label={entry.title}
            sx={cellContentCentered}
            TrueIcon={trueCircleIcon}
            FalseIcon={falseCircleIcon}
            sortable={false}
          />
        ))}
        <SelectField
          source="identityPermission"
          label="Identity Report Permission"
          choices={IDENTITY_PERMISSION_CHOICES}
          emptyText="--"
        />
        <FunctionField
          source="customReminderIntervals"
          label="Email Reminder Intervals (Days)"
          sortable={false}
          render={(record) => {
            return (
              <>
                {
                  isEmpty(record.customReminderIntervals) ?
                    "--" :
                    <Tooltip
                      placement="top"
                      title={record.customReminderIntervals.map(interval => interval.interval).join(', ')}
                    >
                      <span style={cursor.pointer}>
                        {record.customReminderIntervals.length} interval{record.customReminderIntervals.length > 1 ? 's' : ''}
                      </span>
                    </Tooltip>
                }
              </>
            )
          }}
        />
        <BooleanField
          source="finalReminderNotification"
          label="Final Reminder Notification"
          sx={cellContentCentered}
          TrueIcon={trueCircleIcon}
          FalseIcon={falseCircleIcon}
          sortable={false}
          />
        <FunctionField
          source="clientStatus"
          label="Client Status"
          render={(record) => {            
            return (isEmpty(record.clientStatus) ? (CLIENT_STATUS_NAME.ACTIVE) : (CLIENT_STATUS_NAME[record.clientStatus]))
          }}
        />
        {/* react admin doesnt have anything as viewbutton -- figure this out & tooltips not */}
        <FunctionField
          sx={cellContentCentered}
          source="View"          
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip
              title="View"
              placement="top"
            >
              <ShowButton
                icon={<RemoveRedEyeOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={false}
                basePath={RESOURCES_BASEPATH.CORPORATE_PARTNERS}
                record={record}
              />
            </CustomTooltip>
          )}
        />
        <FunctionField
          sx={cellContentCentered}
          source="Edit"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip
              title="Edit"
              placement="top"
            >
              <EditButton
                icon={<EditOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={false}
                basePath={RESOURCES_BASEPATH.CORPORATE_PARTNERS}
                record={record}
              />
            </CustomTooltip>
          )}
        />
        <FunctionField
          sx={cellContentCentered}
          source="Delete"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip 
              title="Delete" 
              placement="top"
            >
              <CustomDeleteWithConfirmButton
                icon={<DeleteOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={false}
                record={record}
                onSuccess={onDeleteSuccess}
                recordName="Corporate Partner"
                mutationMode="pessimistic"
                onFailure={onDeleteFailure}
                disableDelete={disableDelete}
                setDisableDelete={setDisableDelete}
              />
            </CustomTooltip>
          )}
        />
      </DatagridConfigurable>
    </List>
  );
}
