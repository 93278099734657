import {IDENTITY_VERIFICATION_REQUEST_STATUS_KEYS as STATUS_KEYS, RESOURCES, SUB_RESOURCES } from '../components/Constants';
import { isValidUUID } from '../utils/util';
import { parseCorporatePartnerData } from './CorporatePartner';
import { parseIdReportsData } from './IdentityVerification';
import { parseMattersData } from './Matter';
import { parseReportsData } from './Report';
import { parseScheduleData } from './Schedule'
import {parseDataStatusMessageShow} from "./StatusMessages";


export const parseClientNamesData = (data) => {
  var result = [];
  data.forEach((item) => {
    result.push({
      id: item.clientId,
      name: item.clientName,
    });
  });
  return result;
};

export const parseMappedUsersData = (data) => {
  var userSet = new Map();
  data.forEach((user) => {
    userSet.set(
      user.userId,
      user.firstName != null && user.lastName != null ? `${user.firstName} ${user.lastName}` : ' '
    );
  });
  return userSet;
};

export const prepareFilterString = (resource, filter) => {
  let filterString = '';
  if (filter) {
    if (filter.searchValue !== undefined) {
      if (resource === RESOURCES.REPORTS) {
        filterString += isValidUUID(filter.searchValue.trim())
          ? (filter.reportRequestScheduleId === filter.searchValue.trim() ? 'reportRequestScheduleId:eq:' : (filter.matterId === filter.searchValue.trim() ? 'matterId:eq:' : 'requestId:eq:')) +
            filter.searchValue.trim() +
            ','
          : 'clientReference:like:' + filter.searchValue + ','; // sending clientReference as rest of the fields inside requestParams come after this field
      }else if (resource === RESOURCES.USERS && filter.searchKey === 'email') {
        filterString += filter.searchKey + ':like:' + filter.searchValue.trim() + ',';
      }else if (resource === RESOURCES.MATTERS) {
        filterString += 'name:like:' + filter.searchValue.trim() + ',';
      }else if (resource === RESOURCES.IDENTITY_VERIFICATION) {
        filterString += isValidUUID(filter.searchValue.trim()) && !filter.fromReport                  
        ? 'id:eq:' + filter.searchValue.trim() + ','
        : 'reportRequestParams:like:' + filter.searchValue + ',';
      }
      else {
        filterString += filter.searchKey + ':like:' + filter.searchValue + ',';
      }
    }
    if (filter.status !== undefined) {
      if(resource === RESOURCES.IDENTITY_VERIFICATION){
        if(filter.status === 'NEW'){        
          filterString += `status:in:${STATUS_KEYS.NEW},${STATUS_KEYS.PENDING},${STATUS_KEYS.IDENTITY_DATA_PERSISTED},${STATUS_KEYS.FILES_PROCESSED},`;        
        }else{
          filterString += 'status:eq:' + filter.status + ',';
        }
      }else{
        filterString += 'status:like:' + filter.status + ',';
      }      
    }
    if (resource === RESOURCES.REPORTS) {
      filterString += 'hidden:eq:' + (filter.hidden ? 'true' : 'false') + ',';

      if (filter.requestingUserId !== undefined) {
        filterString += 'requestingUserId:eq:' + filter.requestingUserId + ',';
      }
    }
    //schedule related filter's
    if (resource === RESOURCES.SCHEDULES) {
      if (filter.scheduleSearchValue !== undefined) {
        filterString += 'clientReference:like:' + filter.scheduleSearchValue + ',';
      }
      if (filter.scheduleStatus !== undefined) {
        filterString += 'active:eq:' + (filter.scheduleStatus === 'ACTIVE' ? true : false) + ',';
      }
      if (filter.scheduleFrequency !== undefined) {
        filterString += 'frequency:eq:' + filter.scheduleFrequency + ',';
      }
    }
  }
  if (resource === RESOURCES.MATTERS) {
    if(filter.userId !== undefined) {
      filterString  += 'userId:eq:' + filter.userId + ',';
    }
  }
  if (resource === RESOURCES.IDENTITY_VERIFICATION) {
    if(filter.requestingUserId !== undefined) {
      filterString  += 'requestingUserId:eq:' + filter.requestingUserId + ',';
    }
    if(filter.hasUnAcknowledgedMatch === true) {
      filterString += 'hasUnAcknowledgedMatch:eq:true,';
    }
    if(filter.screeningMatchStatus !== undefined) {
      filterString += 'screeningMatchStatus:eq:' + filter.screeningMatchStatus + ',';
    }
  }
  if (filterString.length > 0) {
    filterString = filterString.substring(0, filterString.length - 1);
  }
  return filterString;
};

export const getParsedFieldForSort = (field, resource) => {
  if (resource === RESOURCES.CORPORATE_PARTNERS) {
    if (field === 'name') return 'clientName';
    if (field === 'primaryUser') return 'email';
  }
  if (resource === RESOURCES.REPORTS || resource === RESOURCES.MATTERS) {
    return field;
  }
  if (resource === RESOURCES.SCHEDULES) {
    if (field === 'frequency') return 'frequency';
    if (field === 'status') return 'active';
  }
  if (resource === RESOURCES.USERS) {
    return field;
  }
  if (resource === SUB_RESOURCES.TRANSACTIONS) {
    return field;
  }
  if(resource === RESOURCES.IDENTITY_VERIFICATION){
    if(field === 'lastModified'){
      return 'updatedAt';
    }
    return field;
  }
};

export const DATA_PARSERS = {
  [RESOURCES.STATUS_MESSAGES]: parseDataStatusMessageShow,
  [RESOURCES.CORPORATE_PARTNERS]: parseCorporatePartnerData,
  [RESOURCES.REPORTS]: parseReportsData,
  [RESOURCES.SCHEDULES]: parseScheduleData,
  [RESOURCES.MATTERS]: parseMattersData,
  [RESOURCES.IDENTITY_VERIFICATION]: parseIdReportsData,
};