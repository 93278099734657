import * as React from "react";
import {
  List,
  ShowButton,
  EditButton,
  FunctionField,
  TextField,
  useNotify,
  useRefresh,
  useListContext,
  CreateButton,
  Datagrid,
} from 'react-admin'
import {ActionTitles, RESOURCES, RESOURCES_BASEPATH} from '../Constants'
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ListActions from "../common/ListActions";
import CustomTooltip from "../../utils/CustomTooltip";
import CustomDeleteWithConfirmButton from "../../utils/CustomDeleteWithConfirmButton";
import { CustomPagination } from "../common/CustomPaginationComponent";
import {
  cellContentCentered,
  dataGrid,
  iconAction,
  listRoot,
  topActionButtonBaseStyle,
  width
} from '../Styles'
import defaultsDeep from 'lodash/defaultsDeep';
import cloneDeep from 'lodash/cloneDeep';
import {
  Box,
  Typography,
} from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import LabelField from "../common/LabelField";
import DateTimeField from "../common/DateTimeField";

export const StatusMessages = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [disableDelete, setDisableDelete] = React.useState(false);

  const onDeleteSuccess = () => {
    setDisableDelete(false);
    notify('Status Message Deleted', { type: 'info', autoHideDuration: 1000 });
    refresh();
  };

  const onDeleteFailure = () => {
    setDisableDelete(false);
    notify("Unable to delete the Status Message", { type: 'warning', autoHideDuration: 1000 });
    refresh();
  };

  return (
    <List
      sx={listRoot}
      empty={<StatusMessagesEmpty basePath={RESOURCES.STATUS_MESSAGES} {...props} />}
      actions={
        <ListActions
          title={ActionTitles.STATUS_MESSAGES}
          showCreateButton={true}
          basePath={RESOURCES_BASEPATH.STATUS_MESSAGES}
          filters={false}
        />
      }
      exporter={false}
      pagination={<CustomPagination />}
    >
      <Datagrid
          sx={defaultsDeep(cloneDeep(dataGrid.base))}
          bulkActionButtons={false}
      >
        <TextField source="id" label="ID" sortable={false} />
        <TextField source="name" label="NAME" sortable={false} />
        <TextField source="components" label="COMPONENTS" sortable={false} />
        <DateTimeField source="effectiveFrom" label="EFFECTIVE FROM" sortable={false} />
        <DateTimeField source="effectiveTo" label="EFFECTIVE TO" sortable={false} />
        <LabelField source="type" label="TYPE" sortable={false} />
        <FunctionField
          sx={cellContentCentered}
          source="View"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip
              title="View"
              placement="top"
            >
              <ShowButton
                icon={<RemoveRedEyeOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={''}
                basePath={RESOURCES_BASEPATH.STATUS_MESSAGES}
                record={record}
              />
            </CustomTooltip>
          )}
        />
        <FunctionField
          sx={cellContentCentered}
          source="Edit"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip
              title="Edit"
              placement="top"
            >
              <EditButton
                icon={<EditOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={''}
                basePath={RESOURCES_BASEPATH.STATUS_MESSAGES}
                record={record}
              />
            </CustomTooltip>
          )}
        />

        <FunctionField
          sx={cellContentCentered}
          source="Delete"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip
              title="Delete"
              placement="top"
            >
              <CustomDeleteWithConfirmButton
                icon={<DeleteOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={''}
                record={record}
                recordName="StatusMessages"
                onSuccess={onDeleteSuccess}
                onFailure={onDeleteFailure}
                disableDelete={disableDelete}
                setDisableDelete={setDisableDelete}
                mutationMode="pessimistic"
              />
            </CustomTooltip>
          )}
        />
      </Datagrid>
    </List>
  );
}

const StatusMessagesEmpty = () => {
  const { basePath } = useListContext();

  return (
    <Box textAlign="center" sx={{ ...width.fluid.w100, paddingTop: "50px" }}>
      <Typography variant="h5" paragraph>
        There are currently no Status Messages
      </Typography>
      <CreateButton
        sx={{...topActionButtonBaseStyle()}}
        basePath={basePath}
        icon={<AddCircleOutlineIcon />}
        size="small"
        label={ActionTitles.STATUS_MESSAGES_CREATE}
      />
    </Box>
  );
};