import * as React from "react";
import {
  Edit,
  SimpleForm,
  SelectInput,
  TextInput,
  useRecordContext,
  FormDataConsumer,
} from 'react-admin'
import ListActions from "../common/ListActions";
import {
  ActionTitles,
  ENABLED_FEATURES,
  RESOURCES,
  RESOURCES_BASEPATH,
  SURVEY_SUPPORT_TYPES_OPTIONS,
  SURVEY_SUPPORT_TYPES,
  CLIENT_STATUS_CHOICES,
  REPORT_BRANDING_TYPES_OPTIONS,
  REPORT_BRANDING_TYPES,
  EXCLUDE_ENABLED_FEATURES_FOR_FORM,
  ENABLED_FEATURES_ENUM,
  IDENTITY_PERMISSION_CHOICES
} from '../Constants'
import CustomToolbar from "../common/FormToolbar";
import {
  textColor,
  contentCenter,
  padding0,
  padding2,richTextContent,width,simpleForm
} from '../Styles'
import {Grid} from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import {
  emailWithSpacesValidation,
  requiredValidation,
  secondaryEmailWithSpacesValidation,
  urlValidation
} from '../../utils/util'
import Divider from '@mui/material/Divider'
import {BooleanInput, ImageInput} from 'ra-ui-materialui'
import PreviewImage from '../common/ImageInputPreview'
import ColorFields from '../common/ColorFields'
import {BooleanInputComponent} from '../common/BooleanComponents'
import TemplateAccordian, {TemplateDialog} from './TemplateAccordian'
import SurveyAccordion, {SurveyDialog} from './SurveyAccordion'
import { CustomEmailReminder } from "../common/CustomEmailReminderIntervals";
import PersonaTemplateAccordian from "./PersonaTemplateAccordian";
import PersonaTemplateDialog from "./PersonaTemplateDialog";

const RichTextInput = React.lazy(() =>
  import('ra-input-rich-text').then(module => ({
    default: module.RichTextInput,
  }))
);

export const CorporatePartnerEdit = () => (
  <Edit
    actions={
      <ListActions
        title={ActionTitles.CORPORATE_PARTNER_UPDATE}
        showViewButton={true}
        showDeleteButton={true}
        basePath={RESOURCES_BASEPATH.CORPORATE_PARTNERS}
        resource={RESOURCES.CORPORATE_PARTNERS}
        recordName={ActionTitles.CORPORATE_PARTNER}
      />
    }
    renderButtonAndSearch={false}
    sx={contentCenter}
    mutationMode="pessimistic"
  >
    <SimpleForm
      toolbar={
        <CustomToolbar
          buttontitle1={ActionTitles.UPDATE_AND_CONTINUE}
          buttontitle2={ActionTitles.UPDATE}
          isEdit={true}
          basePath={RESOURCES_BASEPATH.CORPORATE_PARTNERS}
          resource={RESOURCES.CORPORATE_PARTNERS}
        />
      }
      mode="onBlur"
      reValidateMode="onBlur"
      sx={simpleForm}
    >
      <CorporatePartnerCreateEditGrid isEdit={true}/>
    </SimpleForm>
  </Edit>
);

export const CorporatePartnerCreateEditGrid = (props) => {
  const record = useRecordContext();

  return (
    <Grid container flexGrow={1}>
      <Grid item xs={12}>
        <List sx={padding0.y}>
          <ListItem sx={padding2.y} key="clientName">
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>
                Name
                <span style={{...textColor.red, marginLeft: '3px'}}>*</span>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <TextInput
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                  name="clientName"
                  label="Name"
                  variant="outlined"
                  size="small"
                  validate={requiredValidation}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y} key="email">
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>
                Primary User Email
                <span style={{...textColor.red, marginLeft: '3px'}}>*</span>
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <TextInput
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                  name="email"
                  label="Email"
                  variant="outlined"
                  size="small"
                  validate={[requiredValidation, emailWithSpacesValidation]}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y} key="notes">
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>Notes</Grid>
              <Grid item xs={12} md={6} lg={5}>
                <TextInput
                  label="Notes"
                  name="notes"
                  multiline
                  variant="outlined"
                  rows={2}
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y} key="logoUpload">
            <Grid container>
              <Grid item xs={12} md={4} sx={{...textColor.gray, marginTop: {md: "30px"} }}>Logo</Grid>
              <Grid item xs={12} md={6} lg={5}>
                <React.Fragment>
                  <ImageInput
                    source="icon"
                    label="Upload logo"
                    accept="image/*"
                    multiple={false}
                    sx={{marginBottom: "-15px"}}
                  >
                    <PreviewImage source="icon" />
                  </ImageInput>
                </React.Fragment>
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y} key="logoUrl">
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>Logo URL</Grid>
              <Grid item xs={12} md={6} lg={5}>
                <TextInput
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                  name="logoUrl"
                  label="Logo URL"
                  variant="outlined"
                  size="small"
                  validate={urlValidation}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ColorFields />
          <Divider light />
          <ListItem sx={padding2.y} key="reportBranding">
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>Report Branding</Grid>
              <Grid item xs={12} md={6} lg={5}>
                <SelectInput
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                  source="reportBranding"
                  name="reportBranding"
                  variant="outlined"
                  size="small"
                  label="Choose an option"
                  choices={REPORT_BRANDING_TYPES_OPTIONS}
                  defaultValue={REPORT_BRANDING_TYPES.DEFAULT}
                  isRequired
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y} key="reportScheduling">
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>Report Scheduling</Grid>
              <Grid item xs={12} md={8}>
                <BooleanInput
                  sx={{marginBottom: "-15px"}}
                  label={false}
                  source="reportScheduling"
                  defaultValue={false}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y} key="surveySupportType">
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>Survey Support Type</Grid>
              <Grid item xs={12} md={6} lg={5}>
                <SelectInput
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                  source="surveySupportType"
                  name="surveySupportType"
                  variant="outlined"
                  size="small"
                  label="Choose an option"
                  choices={SURVEY_SUPPORT_TYPES_OPTIONS}
                  defaultValue={SURVEY_SUPPORT_TYPES.NONE}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y} key="incomeInsightsPermission">
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>Income Insights Permission</Grid>
              <Grid item xs={12} md={8}>
                <BooleanInput
                  sx={{marginBottom: "-15px"}}
                  label={false}
                  source="incomeInsightsPermission"
                  defaultValue={false}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y} key="riskInsightsPermission">
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>Risk Insights Permission</Grid>
              <Grid item xs={12} md={8}>
                <BooleanInput
                  sx={{marginBottom: "-15px"}}
                  label={false}
                  source="riskInsightsPermission"
                  defaultValue={false}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          {ENABLED_FEATURES
          .filter(feature => !EXCLUDE_ENABLED_FEATURES_FOR_FORM.includes(feature.name))
          .map((entry) => (
            <BooleanInputComponent
              source={entry.name}
              title={entry.title}
            />
          ))}
          <ListItem sx={padding2.y} key="identityPermission">
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>Identity Report Permission</Grid>            
              <Grid item xs={12} md={8} lg={5}>
                <SelectInput
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                  source="identityPermission"
                  name="identityPermission"
                  variant="outlined"
                  size="small"
                  label="Choose an option"
                  choices={IDENTITY_PERMISSION_CHOICES}                           
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <BooleanInputComponent
            source={ENABLED_FEATURES_ENUM.MATTER_ENABLED}
            title={ENABLED_FEATURES
              .filter(feature => feature.name === ENABLED_FEATURES_ENUM.MATTER_ENABLED)[0].title}
          />
          <FormDataConsumer>
            {
              ({formData, ...rest}) => formData[ENABLED_FEATURES_ENUM.MATTER_ENABLED] && 
              <>                
                <ListItem sx={padding2.y} key={`Import CSV Input`}>
                  <Grid container>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={8}>
                      <Grid container alignItems="center">
                      <Grid xs={12} md={4} sx={textColor.gray}>
                        {ENABLED_FEATURES
                        .filter(feature => feature.name === ENABLED_FEATURES_ENUM.IMPORT_CSV)[0].title}
                      </Grid>
                      <Grid xs={8}>
                        <BooleanInput
                          sx={{marginBottom: "-15px"}}
                          label={false}
                          source={ENABLED_FEATURES_ENUM.IMPORT_CSV}
                          defaultValue={false}                          
                        />
                      </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider light />
                <ListItem sx={padding2.y} key={`Upload Bank Statement Input`}>
                  <Grid container>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={8}>
                      <Grid container alignItems="center">
                      <Grid xs={12} md={4} sx={textColor.gray}>
                        {ENABLED_FEATURES
                        .filter(feature => feature.name === ENABLED_FEATURES_ENUM.UPLOAD_BANK_STATEMENT)[0].title}
                      </Grid>
                      <Grid xs={8}>
                        <BooleanInput
                          sx={{marginBottom: "-15px"}}
                          label={false}
                          source={ENABLED_FEATURES_ENUM.UPLOAD_BANK_STATEMENT}
                          defaultValue={false}                          
                        />
                      </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider light />                
              </>
            }
          </FormDataConsumer>                    
          <ListItem sx={padding2.y} key="clientStatus">
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>Client Status</Grid>            
              <Grid item xs={12} md={8} lg={5}>
                <SelectInput
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                  source="clientStatus"
                  name="clientStatus"
                  variant="outlined"
                  size="small"
                  label="Choose an option"
                  choices={CLIENT_STATUS_CHOICES}                           
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y} key="replyToEmail">
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>Reply-To Email</Grid>
              <Grid item xs={12} md={6} lg={5}>
                <TextInput
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                  name="replyToEmail"
                  label="Reply To Email"
                  variant="outlined"
                  size="small"
                  validate={secondaryEmailWithSpacesValidation}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y} key="segment">
            <Grid container alignItems="center">
              <Grid item xs={12} md={4} sx={textColor.gray}>Segment</Grid>
              <Grid item xs={12} md={6} lg={5}>
                <TextInput
                  sx={{...width.fluid.w100, marginBottom: "-15px"}}
                  name="segment"
                  label="Segment"
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <ListItem sx={padding2.y} key="landingScreenContent">
            <Grid container>
              <Grid item xs={12} md={4} sx={{...textColor.gray, marginTop: { md: "52px"} }}>Landing Screen Content</Grid>
              <Grid item xs={12} md={8}>
                <RichTextInput
                  name="landingScreenContent"
                  source="landingScreenContent"
                  variant="outlined"
                  label={false}
                  sx={{...richTextContent, marginBottom: "-15px"}}
                />
              </Grid>
            </Grid>
          </ListItem>
          <Divider light />
          <CustomEmailReminder />
          <BooleanInputComponent
            source="finalReminderNotification"
            title="Final Reminder Notification"
          />
          {
            (props.isEdit)
              ?
              <TemplateAccordian clientId={record["clientId"]} isEdit={props.isEdit}/>
              :
              <ListItem sx={padding2.y} key="templates">
                <Grid container>
                  <Grid item xs={12} md={4} sx={{ ...textColor.gray, marginTop: { md: props.isEdit ? "18px" : "10px" } }}>Templates</Grid>
                  <Grid item xs={12} md={8}>
                    <TemplateDialog />
                  </Grid>
                </Grid>
              </ListItem>
          }
          <Divider light />
          {
            (props.isEdit)
              ?
              <SurveyAccordion clientId={record["clientId"]} isEdit={props.isEdit}/>
              :
              <ListItem sx={padding2.y} key="surveys">
                <Grid container>
                  <Grid item xs={12} md={4} sx={{ ...textColor.gray, marginTop: { md: "18px" } }}>Surveys</Grid>
                  <Grid item xs={12} md={8}>
                    <SurveyDialog />
                  </Grid>
                </Grid>
              </ListItem>
          }
          <Divider light />
          {
            (props.isEdit)
              ?
              <PersonaTemplateAccordian clientId={record["clientId"]} isEdit={true} isShow={false} />
              :
              <ListItem sx={padding2.y} key="personaTemplates">
                <Grid container>
                  <Grid item xs={12} md={4} sx={{ ...textColor.gray, marginTop: { md: props.isEdit ? "18px" : "10px" } }}>Identity Templates</Grid>
                  <Grid item xs={12} md={8}>
                    <PersonaTemplateDialog />
                  </Grid>
                </Grid>
              </ListItem>
          }          
        </List>
        <Divider light />
      </Grid>
    </Grid>
  );
};