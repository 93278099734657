import * as React from "react";
import { Admin, Resource, defaultTheme, localStorageStore } from "react-admin";
import { QueryClient } from "react-query";
import TagManager from 'react-gtm-module';
import { Error404 } from './components/errors/Error404'
import { CorporatePartners } from "./components/corporatePartners/CorporatePartners";
import { CorporatePartnerShow } from './components/corporatePartners/CorporatePartnerShow'
import { CorporatePartnerCreate } from './components/corporatePartners/CorporatePartnerCreate'
import { CorporatePartnerEdit } from './components/corporatePartners/CorporatePartnerEdit'
import { DefaultContent } from './components/defaultContent/DefaultContent'
import { DefaultContentShow } from './components/defaultContent/DefaultContentShow'
import { DefaultContentCreate } from './components/defaultContent/DefaultContentCreate'
import { DefaultContentEdit } from './components/defaultContent/DefaultContentEdit'
import { Reports } from './components/reports/Reports'
import { ReportShow } from './components/reports/ReportShow'
import { ReportCreate } from "./components/reports/ReportCreate";
import { Schedules } from "./components/schedules/Schedules";
import { ScheduleShow } from './components/schedules/ScheduleShow'
import { RiskInsights } from './components/riskInsights/RiskInsights'
import { RiskInsightsCreate } from './components/riskInsights/RiskInsightsCreate'
import { RiskInsightEdit } from './components/riskInsights/RiskInsightsEdit'
import { ClientDefaults } from './components/clientDefaults/ClientDefaults'
import { ClientDefaultsCreate } from './components/clientDefaults/ClientDefaultsCreate'
import { ClientDefaultsEdit } from './components/clientDefaults/ClientDefaultsEdit'
import { Settings } from './components/settings/Settings'
import { SettingsShow } from './components/settings/SettingsShow'
import { SettingsEdit } from './components/settings/SettingsEdit'
import { Users } from "./components/users/Users";
import { UserShow } from './components/users/UserShow'
import { UserCreate } from "./components/users/UserCreate";
import { UserEdit } from './components/users/UserEdit'
import CustomLayout from "./components/common/layout/Layout";
import { StatusMessages } from "./components/statusMessages/StatusMessages";
import {
  CalendarIcon,
  corporatePartnerIcon,
  defaultContentIcon,
  reportsIcon,
  SettingsIcon,
  UsersIcon,
  RiskInsightIcon,
  ClientDefaultsIcon,
  StatusMessagesIcon,
  mattersIcon,
  PersonSearch,
} from "./components/common/StyledIcons";
import dataProvider, { isAdminUser, isClientAdminUser, isReportSchedulingEnabled, isRiskInsightsEnabled, isClientSuspended, httpClient, isFeatureEnabled, showFileRoute, isValidUser } from "./components/DataProvider";
import { ENABLED_FEATURES_ENUM, LOCAL_STORAGE_VERSION, RESOURCES, RESOURCES_META_DATA, UNAUTHORIZED_ACCESS } from './components/Constants';
import { pdfjs } from 'react-pdf';
import { ClientSuspendedComponent } from "./components/common/ClientSuspended";
import { parseActiveStatusMessages } from "./mappers/StatusMessage";
import Context from "./Context";
import {StatusMessageShow} from "./components/statusMessages/StatusMessageShow";
import {StatusMessageCreate} from "./components/statusMessages/StatusMessageCreate";
import {StatusMessageEdit} from "./components/statusMessages/StatusMessageEdit";
import { Route } from "react-router-dom";
import { FileUpload } from "./components/matters/FileUpload";
import { ViewAccount } from "./components/matters/AccountShow";
import { Matters } from "./components/matters/Matters";
import { MatterCreate } from "./components/matters/MatterCreate";
import { MatterShow } from "./components/matters/MatterShow";
import { MatterEdit } from "./components/matters/MatterEdit";
import { AccessDenied } from "./components/common/AccessDenied";
import { IdReports } from "./components/Identity/IdentityVerificationList";
import { IdentityRequestShow } from "./components/Identity/IdentityRequestShow";
import { ViewScreeningReport } from "./components/Identity/ViewScreeningReport";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const gtmTagId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

const armalytixTheme = {
  ...defaultTheme,
  sidebar: {
    width: 250,
  },
  typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: ['"Gilroy"', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', '"Roboto"', '"Oxygen"', '"Ubuntu"', '"Cantarell"', 
      '"Fira Sans"', '"Droid Sans"', '"Helvetica Neue"', 'sans-serif'].join(','),
  },
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => {
  const isAdmin = isAdminUser();
  const isClientAdmin = isClientAdminUser();
  const isValidPortalUser = isValidUser();
  const schedulingEnabled = isReportSchedulingEnabled();
  const riskInsightEnabled = isRiskInsightsEnabled();
  const clientSuspendedStatus = isClientSuspended();
  const matterEnabled = isFeatureEnabled(ENABLED_FEATURES_ENUM.MATTER_ENABLED);
  const [statusMessage, setStatusMessage] = React.useState([]);
  const enableFileRoute = showFileRoute();

  if(gtmTagId) {
    TagManager.initialize({gtmId: gtmTagId});
  }

  React.useEffect(() => {
    const getActiveStatusMessages = () => {
      const apiUrl = process.env.REACT_APP_API_ENDPOINT;
      const apiEndpoint = `${apiUrl}/status-message/active?component=CORPORATE_PORTAL`;
      httpClient(
        apiEndpoint, {
          method: "GET",
        })
        .then(({json}) => {
          setStatusMessage(parseActiveStatusMessages(json.data))
        });
    }
    if(!isAdmin){
      getActiveStatusMessages();
    }
  },[isAdmin])

  return (
    <Context.Provider value={{statusMessage : statusMessage}}>
    <Admin
      theme={armalytixTheme}
      dataProvider={dataProvider}
      layout={CustomLayout}
      disableTelemetry={true}
      queryClient={queryClient}
      catchAll={Error404}
      store={localStorageStore(LOCAL_STORAGE_VERSION)}    //Invalidate local storage to apply updates for new columns or user preferences
    >
      {isAdmin ? (
        <Resource
          name={RESOURCES.CORPORATE_PARTNERS}
          list={CorporatePartners}
          create={CorporatePartnerCreate}
          icon={corporatePartnerIcon}
          edit={CorporatePartnerEdit}
          show={CorporatePartnerShow}
          options={{
            label:
              RESOURCES_META_DATA[RESOURCES.CORPORATE_PARTNERS].displayName,
          }}
        />
      ) : null}
      {isAdmin ? (
        <Resource
          name={RESOURCES.DEFAULT_CONTENT}
          list={DefaultContent}
          create={DefaultContentCreate}
          show={DefaultContentShow}
          edit={DefaultContentEdit}
          icon={defaultContentIcon}
          options={{
            label:
              RESOURCES_META_DATA[RESOURCES.DEFAULT_CONTENT].displayName,
          }}
        />
      ) : null}
      <Resource
        name={RESOURCES.REPORTS}
        list={
          !isValidPortalUser || clientSuspendedStatus ? (
            (clientSuspendedStatus && <ClientSuspendedComponent />) ||
            (!isValidPortalUser && (
              <AccessDenied
                title={UNAUTHORIZED_ACCESS.title}
                message1={UNAUTHORIZED_ACCESS.message1}
                message2={UNAUTHORIZED_ACCESS.message2}
              />
            ))
          ) : (
            <Reports />
          )
        }
        create={
          !isValidPortalUser || clientSuspendedStatus ? (
            (clientSuspendedStatus && <ClientSuspendedComponent />) ||
            (!isValidPortalUser && (
              <AccessDenied
                title={UNAUTHORIZED_ACCESS.title}
                message1={UNAUTHORIZED_ACCESS.message1}
                message2={UNAUTHORIZED_ACCESS.message2}
              />
            ))
          ) : (
            <ReportCreate />
          )
        }
        show={
          !isValidPortalUser || clientSuspendedStatus ? (
            (clientSuspendedStatus && <ClientSuspendedComponent />) ||
            (!isValidPortalUser && (
              <AccessDenied
                title={UNAUTHORIZED_ACCESS.title}
                message1={UNAUTHORIZED_ACCESS.message1}
                message2={UNAUTHORIZED_ACCESS.message2}
              />
            ))
          ) : (
            <ReportShow />
          )
        }
        icon={reportsIcon}
        options={{
          label: RESOURCES_META_DATA[RESOURCES.REPORTS].displayName,
        }}
      />
      <Resource
        name={RESOURCES.IDENTITY_VERIFICATION}
        icon={PersonSearch}
        list={IdReports}
        create={ReportCreate}
        show={IdentityRequestShow}
        options={{
          label: RESOURCES_META_DATA[RESOURCES.IDENTITY_VERIFICATION]?.displayName,
        }}
      >
        <Route path=":requestId/screening-report/:reportId" element={<ViewScreeningReport/>} />
        <Route path="client/:clientId/identity-verification/:requestId/screening-report/:reportId" element={<ViewScreeningReport/>} />
      </Resource>
      <Resource
        name={RESOURCES.MATTERS}
        list={(isAdmin || matterEnabled) ? <Matters /> : <Error404 />}
        icon={mattersIcon}
        create={matterEnabled ? <MatterCreate /> : <Error404 />}
        edit={matterEnabled ? <MatterEdit /> : <Error404 />}
        show={(isAdmin || matterEnabled) ? <MatterShow /> : <Error404 />}
        options={{
          label: RESOURCES_META_DATA[RESOURCES.MATTERS].displayName,
        }}
      >
        {enableFileRoute &&
          <Route path=":id/file" element={<FileUpload />} />
        }
        <Route path=":matterId/accounts/:accountId" element={(isAdmin || matterEnabled) ? <ViewAccount/> : <Error404 />} />
      </Resource>      
      {schedulingEnabled ? (
        <Resource
          name={RESOURCES.SCHEDULES}
          list={Schedules}
          icon={CalendarIcon}
          show={ScheduleShow}
          options={{
            label : RESOURCES_META_DATA[RESOURCES.SCHEDULES].displayName,
          }}
        />) : null}
      {(isClientAdmin && riskInsightEnabled) || isAdmin ? (
        <Resource
          name={RESOURCES.RISK_INSIGHTS}
          list={RiskInsights}
          icon={RiskInsightIcon}                
          edit={RiskInsightEdit}
          create={RiskInsightsCreate}
          options={{
            label: RESOURCES_META_DATA[RESOURCES.RISK_INSIGHTS].displayName,
          }}
        />
      ) : null}          
      { isAdmin || (isClientAdmin && !clientSuspendedStatus) ? (
        <Resource
          name={RESOURCES.CLIENT_DEFAULTS}
          list={ClientDefaults}
          icon={ClientDefaultsIcon}
          edit={ClientDefaultsEdit}
          create={ClientDefaultsCreate}
          options={{
            label: RESOURCES_META_DATA[RESOURCES.CLIENT_DEFAULTS].displayName,
          }}
        />
      ) : null }
      { isAdmin || isClientAdmin ? (
        <Resource
          name={RESOURCES.USERS}
          list={Users}
          icon={UsersIcon}
          show={UserShow}
          edit={UserEdit}
          create={UserCreate}
          options={{
            label: RESOURCES_META_DATA[RESOURCES.USERS].displayName,
          }}
        />
      ) : null }         
      <Resource
        name={RESOURCES.SETTINGS}
        list={Settings}
        icon={SettingsIcon}
        show={SettingsShow}
        edit={SettingsEdit}
        options={{
          label: RESOURCES_META_DATA[RESOURCES.SETTINGS].displayName,
        }}
      />                
      <Resource
        name={RESOURCES.SURVEYS}
      />
      { isAdmin ? (
        <Resource
          name={RESOURCES.STATUS_MESSAGES}
          list={StatusMessages}
          create={StatusMessageCreate}
          icon={StatusMessagesIcon}
          edit={StatusMessageEdit}
          show={StatusMessageShow}
          options={{
            label: RESOURCES_META_DATA[RESOURCES.STATUS_MESSAGES].displayName,
          }}
        />
      ) : null }                 
    </Admin>
    </Context.Provider>
  );
};

export default App;
