import {
  Create,
  Datagrid,
  FunctionField,
  Loading,
  TextField,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,
  Button,
  useList,
  ListContextProvider,
  useListContext,
} from "react-admin";
import Switch from '@mui/material/Switch';
import {
  baseButtonStyle,
  cellContentCentered,
  contentCenter,
  dataGrid,
  dialog,
  iconAction,
  listRoot,
  padding0,
  removeBoxShadow,
  textColor,
  tooltipLabelStyle,
} from "../Styles";
import { ActionTitles, RESOURCES, RESOURCES_BASEPATH } from "../Constants";
import CustomTooltip from "../../utils/CustomTooltip";
import defaultsDeep from 'lodash/defaultsDeep';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import cloneDeep from 'lodash/cloneDeep';
import CustomDeleteWithConfirmButton from "../../utils/CustomDeleteWithConfirmButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Grid, ListItem, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { CreateReportForm } from "../reports/ReportCreate";
import CustomDialog from "../../utils/CustomDialog";
import CustomToolbar from "../common/FormToolbar";
import * as React from "react";
import { useLocation } from "react-router";
import { isAdminUser } from "../DataProvider";

export const MatterAccountsGrid = (props) => {
  const isAdmin = isAdminUser();
  const notify = useNotify();
  const location = useLocation();
  const data = props.accountsList.data;
  const listContext = useList({ data });
  const [disableDelete, setDisableDelete] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState(new Set());
  const [disableReportGeneration, setDisableReportGeneration] = useState(true);
  const dataProvider = useDataProvider();
  const [bankDataMap, setBankDataMap] = useState(new Map());
  const [loading, setLoading] = useState(true);
  const redirect = useRedirect();

  useEffect(() => {
    fetchBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBanks = () => {
    dataProvider.getAllBanks()
      .then((bankData) => {
        setBankDataMap(bankData.data);
        setLoading(false);
      })
      .catch((error) => {
        notify('Something went wrong while fetching Banks.', "warning", { autoHideDuration: 1000 });
        setLoading(false);
      });
  }

  const onDeleteSuccess = (res) => {
    notify('Account Deleted', { type: "info", autoHideDuration: 1000 });
    setDisableDelete(false);

    setSelectedAccounts((accounts) => {
      accounts.delete(res.data.id);
      return accounts;
    });
    if (selectedAccounts.size === 0)
      setDisableReportGeneration(true);

    props.refresh();
  };

  const onDeleteFailure = () => {
    notify("Unable to delete the Account", { type: 'warning', autoHideDuration: 1000 });
    setDisableDelete(false);
    props.refresh();
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && data && data.length &&
        <ListContextProvider value={listContext}>
          <Grid
            sx={listRoot["& .RaList-content"]}>
            <Datagrid
              sx={
                defaultsDeep(
                  cloneDeep(dataGrid.base),
                  cloneDeep(dataGrid.firstColSticky)
                )
              }
              bulkActionButtons={false}
              empty={null}
            >
              <FunctionField
                source="bankId"
                label="BANK"
                sortable={false}
                render={(record) => bankDataMap.get(record.bankId)}
              />
              <TextField
                source="accountNumber"
                label="ACCOUNT NUMBER"
                sortable={false}
              />
              <TextField
                sx={cellContentCentered}
                source="txnCount"
                label="TRANSACTIONS"
                sortable={false}
              />
              <TextField
                source="dateFrom"
                label="DATE FROM"
                sortable={false}
              />
              <TextField
                source="dateTo"
                label="DATE TO"
                sortable={false}
              />
              <TextField
                source="lastModified"
                label="LAST MODIFIED"
                sortable={false}
              />
              <FunctionField
                source="lastModifiedBy"
                label="MODIFIED BY"
                sortable={false}
                render={(record) =>
                  props.usersMap && props.usersMap.get(record.lastModifiedBy) !== null ?
                    props.usersMap.get(record.lastModifiedBy) : ' '
                }
              />
              <FunctionField
                sx={cellContentCentered}
                source="IntegratyCheck"
                label="INTEGRITY CHECK"
                sortable={false}
                render={(record) =>
                  record.integrityIssueCount > 0 ?
                    <Tooltip
                      disableInteractive
                      placement="right"
                      title={
                        <div sx={tooltipLabelStyle}>
                          {record.integrityIssueCount + (record.integrityIssueCount === 1 ? " issue" : " issues")}
                        </div>
                      }
                    >
                      <WarningAmberIcon style={textColor.error} />
                    </Tooltip>
                    :
                    <Tooltip
                      disableInteractive
                      placement="right"
                      title={<div sx={tooltipLabelStyle}>{"No issues"}</div>}
                    >
                      <CheckCircleIcon style={textColor.success} />
                    </Tooltip>
                }
              />
              {!isAdmin &&
                <AccountSelectSwitch
                  selectedAccounts={selectedAccounts}
                  setSelectedAccounts={setSelectedAccounts}
                  disableReportGeneration={disableReportGeneration}
                  setDisableReportGeneration={setDisableReportGeneration}
                />
              }
              {props.fileUpload &&
                <FunctionField
                  sx={cellContentCentered}
                  source="uploadFiles"
                  label={false}
                  sortable={false}
                  render={(record) => (
                    <CustomTooltip title="Upload Statements" placement="top">
                      <Button
                        sx={iconAction}
                        size="medium"
                        label=""
                        onClick={() => {
                          props.setAccount({
                            accountNumber: record.accountNumber,
                            bankName: bankDataMap.get(record.bankId),
                            currency: 'GBP', //record.currency,
                            sortCode: record.sortCode
                          });
                        }}
                      >
                        <FileUploadIcon />
                      </Button>
                    </CustomTooltip>
                  )}
                />
              }
              <FunctionField
                sx={cellContentCentered}
                source="Show"
                label={false}
                sortable={false}
                render={(record) => (
                  <CustomTooltip title="Show" placement="top">
                    <Button
                      size="medium"
                      sx={iconAction}
                      onClick={() => {
                        if (isAdmin) {
                          let split = record['matterId'].split('/');
                          let clientId = split[1], matterId = split[3];
                          redirect(RESOURCES_BASEPATH.MATTERS + "/" + matterId + RESOURCES_BASEPATH.ACCOUNTS + "/" + record['accountId'] + "?clientId=" + clientId);
                        }
                        else
                          redirect(RESOURCES_BASEPATH.MATTERS + "/" + record['matterId'] + RESOURCES_BASEPATH.ACCOUNTS + "/" + record['accountId']);
                      }}
                    >
                      <RemoveRedEyeOutlinedIcon />
                    </Button>
                  </CustomTooltip>
                )}
              />
              <FunctionField
                sx={cellContentCentered}
                source="Delete"
                label={false}
                sortable={false}
                render={(record) => (
                  <CustomTooltip title="Delete" placement="top">
                    <CustomDeleteWithConfirmButton
                      icon={<DeleteOutlinedIcon />}
                      sx={iconAction}
                      size="medium"
                      label={null}
                      record={record}
                      recordName="Account"
                      mutationMode="pessimistic"
                      resource={RESOURCES.ACCOUNTS}
                      onSuccess={onDeleteSuccess}
                      onFailure={onDeleteFailure}
                      disableDelete={disableDelete}
                      setDisableDelete={setDisableDelete}
                      disabled={isAdmin}
                    />
                  </CustomTooltip>
                )}
              />
            </Datagrid>
          </Grid>
          <MatterAccountToolBar
            basePath={location.pathname.includes("file") ? RESOURCES_BASEPATH.MATTERS + "/" + props.matterId + "/show" : RESOURCES_BASEPATH.MATTERS}
            selectedAccounts={selectedAccounts}
            disableReportGeneration={disableReportGeneration}
            matterId={props.matterId}
            isAdmin={isAdmin}
          />
        </ListContextProvider>
      }
    </>
  );
};

export const AccountSelectSwitch = (props) => {
  const record = useRecordContext();

  useEffect(() => {
    props.setSelectedAccounts((accounts) => {
      accounts.add(record.accountId);
      return accounts;
    });

    if (props.disableReportGeneration)
      props.setDisableReportGeneration(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FunctionField
      sx={cellContentCentered}
      source="accountId"
      label={false}
      sortable={false}
      render={(record) => (
        <Switch
          onChange={(e) => {
            if (e.target.checked) {
              props.setSelectedAccounts((accounts) => {
                accounts.add(record.accountId);
                return accounts;
              });
            }
            else {
              props.setSelectedAccounts((accounts) => {
                accounts.delete(record.accountId);
                return accounts;
              });
            }
            props.setDisableReportGeneration(props.selectedAccounts.size === 0);
          }}
          defaultChecked
        />
      )}
    />
  );
};

export const MatterAccountToolBar = (props) => {
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [matterName, setMatterName] = useState(undefined);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const list = useListContext();

  const handleDialogOpen = () => {
    let minDate, maxDate;
    list.data.forEach((account) => {
      if (props.selectedAccounts.has(account.accountId)) {
        if (minDate === undefined || account.dateFrom < minDate)
          minDate = account.dateFrom;
        if (maxDate === undefined || account.dateTo > maxDate)
          maxDate = account.dateTo;
      }
    });
    setStartDate(minDate);
    setEndDate(maxDate);

    if (matterName === undefined) {
      dataProvider.getOne(RESOURCES.MATTERS, { id: props.matterId })
        .then(({ data }) => {
          setMatterName(data.name);
        }).catch(() => {
          console.log("Could not fetch Matter Details");
        }).finally(() => {
          setReportDialogOpen(true);
        });
    }
    else
      setReportDialogOpen(true);
  };

  const handleDialogClose = () => {
    setReportDialogOpen(false);
  };

  return (
    <ListItem sx={{ ...padding0.x, paddingTop: "16px" }}>
      <Grid item container xs={12} justifyContent="flex-end">
        <Grid item container xs={12} lg={10} justifyContent="flex-end">
          <Grid container item xs={12} md={3}>
            <Button
              variant="outlined"
              icon={<div />}
              size="medium"
              sx={{ ...baseButtonStyle("grey", "white", "#eaeaea", "#b2afaf", "#9B9999"), span: { marginX: 0 } }}
              onClick={() => redirect(props.basePath)}
            >
              {ActionTitles.BACK}
            </Button>
          </Grid>
          <Grid item container xs={12} md={4} lg={3}>
            <Button
              size="medium"
              sx={{ ...baseButtonStyle(), span: { marginX: 0 } }}
              onClick={() => {
                let filterValue = `{"searchValue":"${props.matterId}","matterId":"${props.matterId}"}`;
                if (props.isAdmin) {
                  let split = props.matterId.split('/');
                  let clientId = split[1], matterId = split[3];
                  filterValue = `{"searchValue":"${matterId}","clientId":"${clientId}","matterId":"${matterId}"}`;
                }
                redirect(RESOURCES_BASEPATH.REPORTS + `?filter=${filterValue}&order=DESC&page=1&perPage=10&sort=lastModified`);
              }}
            >
              {ActionTitles.VIEW_REPORTS}
            </Button>
          </Grid>
          <Grid item container xs={12} md={4} lg={3}>
            <Button
              size="medium"
              sx={{ ...baseButtonStyle(), span: { marginX: 0 } }}
              onClick={handleDialogOpen}
              disabled={props.disableReportGeneration}
            >
              {ActionTitles.GENERATE_REPORT}
            </Button>
          </Grid>
          <CustomDialog
            dialogPaperProps={dialog}
            selectedValue={"Report"}
            openDialog={reportDialogOpen}
            closeDialog={handleDialogClose}
            hideDialogActions={true}
            content={
              <Create
                resource={RESOURCES.REPORTS}
                sx={{ ...contentCenter, ...removeBoxShadow }}
              >
                <CreateReportForm
                  matterId={props.matterId}
                  accounts={props.selectedAccounts}
                  matterName={matterName}
                  startDate={startDate}
                  endDate={endDate}
                  isMatterShow={true}
                  toolbar={
                    <CustomToolbar
                      matterReport={true}
                      cancelButtonFunction={handleDialogClose}
                      hideButton1={true}
                      hideButton2={true}
                      buttontitle2={ActionTitles.GENERATE_REPORT}
                      resource={RESOURCES.REPORTS}
                      basePath={RESOURCES_BASEPATH.REPORTS}
                    />
                  }
                />
              </Create>
            }
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};