import { useState, useEffect } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  List,
  TextField,
  ShowButton,
  FunctionField,
  useDataProvider,
  Loading,
  useNotify,
  useRefresh,
  DatagridConfigurable,
  SelectField,
  Button,
  useRedirect,
  downloadCSV,
} from 'react-admin'
import { ActionTitles, IDENTITY_VERIFICATION_REQUEST_STATUS_CHOICES, RESOURCES, RESOURCES_BASEPATH, RESOURCES_META_DATA, SCREENING_MATCH_STATUS_ICON_CHOICES, SCREENING_MATCH_STATUS_KEYS } from "../Constants";
import ListActions from "../common/ListActions";
import CustomTooltip from "../../utils/CustomTooltip";
import CustomDialog from "../../utils/CustomDialog";
import React from "react";
import { isAdminUser } from "../DataProvider";
import isUndefined from "lodash/isUndefined";
import { CustomPagination } from "../common/CustomPaginationComponent";
import {CustomDataGridHeader, onToggleDataGridHeader} from '../common/CustomDataGridHeader'
import {
  cellContentCentered,
  dataGrid,
  iconAction,
  listRoot,
  textColor,
} from '../Styles'
import defaultsDeep from 'lodash/defaultsDeep';
import cloneDeep from 'lodash/cloneDeep';
import { Error404 } from "../errors/Error404";
import { IdentityAdminFilter, IdentityFilter } from "./IdentityFilter";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CancelIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';
import { parseIdRecordsforExport } from "../../mappers/IdentityVerification";
import jsonExport from 'jsonexport/dist';

export const IdReports = (props) => {
  const isAdmin = isAdminUser();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);

  const dataProvider = useDataProvider();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [clientId, setClientId] = useState(undefined);
  let dataGridsLS = JSON.parse(localStorage.getItem("dataGrids"))
  if (!dataGridsLS)
    dataGridsLS = { "ids": true }
  const [dataGrids, setDataGrids] = useState(dataGridsLS ? dataGridsLS : {});

  useEffect(() => {
    if(!isAdmin){
        dataProvider
        .getMappedUsersSummary(`client/mapped-users`)
        .then(({ data }) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider]);

  useEffect(()=>{
    if(isAdmin && !isUndefined(clientId)){
      dataProvider
      .getMappedUsersbyClientId({clientId : clientId})
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }
  }, [clientId, dataProvider, isAdmin])
  
  const pauseContinuousMonitoring = () => {
    dataProvider
    .pauseContinuousMonitoringForAllReport({ id: selectedRecordId })
    .then(() => {      
      notify('Continuous monitoring disabled for all reports', "info", { autoHideDuration: 1000 });
      handleDialogClose();
      refresh();
    })
    .catch(() => {
      notify('Something went wrong while disabling continuous monitoring', "warning", { autoHideDuration: 1000 });
      handleDialogClose();
    });
  }

  const handleDialogClose = () => {
    setDialogOpen(false);    
  };

  const handleIconClick = (recordId) => {    
    setSelectedRecordId(recordId);
    setDialogOpen(true);
  };

  const exporter = (records) => {    
      const list = parseIdRecordsforExport(records, data);
      
      return jsonExport(list, (err, csv) => {
          downloadCSV(csv, RESOURCES_META_DATA[RESOURCES.IDENTITY_VERIFICATION].displayName);        
      });  
    };

  if (loading && !isAdmin) return <Loading />;
  if (error) return <Error404 />;

  return (
    <List
      sx={listRoot}
      actions={
        <ListActions
          title={ActionTitles.IDENTITY_VERIFICATIONS}
          basePath={RESOURCES_BASEPATH.IDENTITY_VERIFICATION}
          renderButtonAndSearch={true}
          showCreateButton={false}
          ShowExportButton={true}
          showColumnsButton={true}
          filters={isAdmin ? <IdentityAdminFilter requestingUserNames={data} setClientId={setClientId} /> : <IdentityFilter requestingUserNames={data}/>}
        />
      }
      sort={{ field: 'updatedAt', order: 'DESC' }}      
      pagination={<CustomPagination />}
      empty={false}
      exporter={exporter}
      filterDefaultValues={{ screeningMatchStatus : SCREENING_MATCH_STATUS_KEYS.MATCH_PENDING }}
    >
      <DatagridConfigurable
        header={
          <CustomDataGridHeader
            fixedColumn={dataGrids['ids']}
            onToggle={() => onToggleDataGridHeader('ids', dataGrids, setDataGrids)}
          />
        }
        sx={
          defaultsDeep(
            cloneDeep(dataGrid.base),
            dataGrids['ids'] ? cloneDeep(dataGrid.firstColSticky) : {}
          )
        }
        bulkActionButtons={false}
      >        
        <TextField source="name" label="Contact Name" sortable={false} />
        <TextField sx={textColor.primary} source="emailAddress" label="Email Address" sortable={false} />
        <SelectField choices={IDENTITY_VERIFICATION_REQUEST_STATUS_CHOICES} source="identityRequestStatus" label="Identity Status" sortable={false}/>
        <FunctionField
          sx={cellContentCentered}
          label="Continuous Monitoring"
          sortable={false}
          render={(record) => (
            <> 
              {record.continuousMonitoringEnabled 
                ?
                 isAdmin 
                 ?
                  <CheckCircleIcon sx={{...textColor.green}} />
                 : 
                  <CustomTooltip title="Click to disable continuous monitoring" placement="top">
                    <Button
                      onClick={!isAdmin && (() => handleIconClick(record.id))}
                      sx={iconAction}
                      key="button"
                    >
                      <CheckCircleIcon
                        sx={{...textColor.green, fontSize: '1.5rem !important'}}
                      />
                    </Button>
                  </CustomTooltip>                                   
                : 
                <CancelIcon sx={textColor.red} />
              }
            </> 
          )}
        />
        <SelectField choices={SCREENING_MATCH_STATUS_ICON_CHOICES} source="screeningMatchStatus" label="Match Status" sortable={false} sx={cellContentCentered} />
        <TextField source="createdAt" label="Created" sortable={true} />
        <TextField source="updatedAt" label="Last Modified" sortable={true} />
        <FunctionField
          render={(record) =>
            !isUndefined(data) && data.get(record.requestingUserId) != null ? data.get(record.requestingUserId) : ' '
          }
          label="Requesting User Name"
          sortable={false}
        />
        <FunctionField
          sx={cellContentCentered}
          source="ViewRequest"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip title="View Request" placement="top">
              <Button
                sx={iconAction}
                size="medium"
                label={""}
                onClick={() => {
                  redirect(
                    isAdmin 
                    ? `${RESOURCES_BASEPATH.REPORTS}/${encodeURIComponent('client/' + clientId + '/detail/' + record['clientRequestId'])}/show` 
                    : `${RESOURCES_BASEPATH.REPORTS}/${record['clientRequestId']}/show`);
                }}
              >
                <DescriptionOutlinedIcon sx={textColor.black} />
              </Button>
            </CustomTooltip>
          )}
        />
        <FunctionField
          sx={cellContentCentered}
          source="View"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip title="View" placement="top">
              <ShowButton
                icon={<RemoveRedEyeOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={""}
                basePath={RESOURCES_BASEPATH.IDENTITY_VERIFICATION}
                record={record}
              ></ShowButton>
            </CustomTooltip>
          )}
        />
      </DatagridConfigurable>
      {!isAdmin && 
        <CustomDialog
          openDialog={dialogOpen}
          closeDialog={handleDialogClose}
          title="Continuous Monitoring Details"
          content={<p>Are you sure you want to disable continuous monitoring for all reports?</p>}
          submitBtnTitle="DISABLE MONITORING"                
          handleSubmit={pauseContinuousMonitoring}
          hideDivider={true}
        />
      }
    </List>
  );
};
