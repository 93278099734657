import {  
  isEmpty,
  Show,
  SimpleShowLayout,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,  
} from 'react-admin'
import ListActions from "../common/ListActions";
import { ActionTitles, IDENTITY_VERIFICATION_REQUEST_STATUS, RESOURCES, RESOURCES_BASEPATH, SCREENING_MATCH_STATUS_ICON, SCREENING_MATCH_STATUS_ICON_FOR_BUTTONS, SCREENING_MATCH_STATUS_TOOLTIP_TITLE } from "../Constants";
import { baseButtonStyle, contentCenter, exitButtonStyle, float, padding0, padding1, padding2, textColor, tooltipLabelStyle, topActionButtonBaseStyle } from "../Styles";
import { Button, Divider, Grid, List, ListItem, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import DisableMonitoringButton from './DisableMonitoringButton';
import { isAdminUser } from '../DataProvider';

export const IdentityRequestShow = () => {
  return (
    <Show
      actions={
        <ListActions 
          title={ActionTitles.IDENTITY_VERIFICATION_DETAILS}
          basePath={RESOURCES_BASEPATH.IDENTITY_VERIFICATION}
          resource={RESOURCES.IDENTITY_VERIFICATION}
          recordName={ActionTitles.IDENTITY_VERIFICATIONS}
        />
      }
      renderButtonAndSearch={false}
      sx={contentCenter}
    >
      <SimpleShowLayout>
        <IdentityShowGrid />
      </SimpleShowLayout>      
    </Show>
  );
};

export const IdentityShowGrid = (props) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const isAdmin = isAdminUser();
  const [personaTemplates, setPersonaTemplates] = useState([]);   
   
  const pauseContinuousMonitoring = () => {
    dataProvider
    .pauseContinuousMonitoringForAllReport({ id : record['id'] })
    .then(() => {      
      notify('Continuous monitoring disabled for all reports', "info", { autoHideDuration: 1000 });
      refresh();
    })
    .catch(() => {
      notify('Something went wrong while disabling continuous monitoring', "warning", { autoHideDuration: 1000 });
    });
  }

  useEffect(() => {
    function getPersonaTemplates() {
      dataProvider
        .getPersonaTemplatesForClient({ clientId : record['clientId'] })
        .then(({ data }) => {
          setPersonaTemplates(data);
        })        
    }
    getPersonaTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const identityVerificationTemplate = personaTemplates?.find(
      pt => record?.templateId && pt?.id === record.templateId
    ) || null;


  return(
  <Grid container flexGrow={1}>
    <Grid item xs={12}>
      <List sx={padding0.y}>
        <IDListItem title="Contact Name" value={record["name"]} />
        <Divider light />
        <IDListItem title="Email Address" value={record["emailAddress"]} />
        <Divider light />
        <IDListItem title="Identity Request Status" value={IDENTITY_VERIFICATION_REQUEST_STATUS[record["identityRequestStatus"]]} />
        <Divider light />
        <IDListItem 
          title="Identity Verification Template"
          value={
            <div style={{ display: "flex", flexDirection: "row" }}>
            <div>{identityVerificationTemplate?.name}</div>
              <Tooltip
                disableInteractive
                placement="right"
                title={
                  <div sx={{ ...tooltipLabelStyle }}>
                    {identityVerificationTemplate?.description}
                  </div>
                }
              >
                <HelpOutlineIcon style={{ fontSize: "medium", color : "gray" }} />
              </Tooltip>
          </div>
          }            
        />
        <Divider light/>        
        <IDListItem title="Continuous Monitoring" value={
          record["continuousMonitoringEnabled"] ?
            (<CheckCircleIcon sx={{ ...textColor.green, ...float.left }} />) :
            (<CancelIcon sx={{ ...textColor.red, ...float.left }} />)
          } />
        <Divider light />
        <IDListItem title="Match Status" value={SCREENING_MATCH_STATUS_ICON[record["screeningMatchStatus"]]} />        
        <Divider light />
        {!isEmpty(record['pepReportId']) && 
          <>
          <IDListItem title="Politically Exposed Person" value={
            <Grid container alignItems="center">
              <Button                
                size="small"
                onClick={() => { redirect(`${RESOURCES_BASEPATH.IDENTITY_VERIFICATION}/${record['id']}/screening-report/${record['pepReportId']}`) }}
                sx={{   ...topActionButtonBaseStyle("white", "#383848", "#181822"), margin : "0px", fontSize: "revert"}}
              >
                View Report
              </Button>
              {record['pepReportMatch'] &&
                <Tooltip title={SCREENING_MATCH_STATUS_TOOLTIP_TITLE[record['pepReportMatch']]} placement="top">
                  {SCREENING_MATCH_STATUS_ICON_FOR_BUTTONS[record['pepReportMatch']]}
                </Tooltip>
              }
            </Grid>
          }/>
          <Divider light />
          </>
        }
        {!isEmpty(record['watchlistReportId']) && 
          <>
          <IDListItem title="Watchlist Report" value={
            <Grid container alignItems="center">
              <Button
                size="small"
                onClick={() => { redirect(`${RESOURCES_BASEPATH.IDENTITY_VERIFICATION}/${record['id']}/screening-report/${record['watchlistReportId']}`) }}
                sx={{   ...topActionButtonBaseStyle("white", "#383848", "#181822"), margin : "0px", fontSize: "revert"}}
              >
                View Report
              </Button>
              {record['watchlistReportMatch'] &&
                <Tooltip title={SCREENING_MATCH_STATUS_TOOLTIP_TITLE[record['watchlistReportMatch']]} placement="top">
                  {SCREENING_MATCH_STATUS_ICON_FOR_BUTTONS[record['watchlistReportMatch']]}                  
                </Tooltip>
              }
            </Grid>
          }/>
          <Divider light />
          </>
        }
        {!isEmpty(record['adverseMediaReportId']) && 
          <>
          <IDListItem title="Adverse Media Report" value={
            <Grid container alignItems="center">
              <Button
                size="small"
                onClick={() => { redirect(`${RESOURCES_BASEPATH.IDENTITY_VERIFICATION}/${record['id']}/screening-report/${record['adverseMediaReportId']}`) }}
                sx={{   ...topActionButtonBaseStyle("white", "#383848", "#181822"), margin : "0px", fontSize: "revert"}}
              >
                View Report
              </Button>
              {record['adverseMediaReportMatch'] &&
                <Tooltip title={SCREENING_MATCH_STATUS_TOOLTIP_TITLE[record['adverseMediaReportMatch']]} placement="top">
                  {SCREENING_MATCH_STATUS_ICON_FOR_BUTTONS[record['adverseMediaReportMatch']]}                  
                </Tooltip>
              }
            </Grid>
          }/>
          <Divider light />
          </>
        }                       
        <IDListItem title="Created" value={record["created"]} />
        <Divider light />
        <IDListItem title="Last Modified" value={record["lastModified"]} />
        <Divider light />                       
        <ListItem sx={padding1.y}>
          <Grid container justifyContent="flex-end">          
            <Grid container item xs={12} lg={10} justifyContent="flex-end">
              <Grid container item xs={12} md={3}>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => redirect(`${RESOURCES_BASEPATH.IDENTITY_VERIFICATION}`)}
                  sx={exitButtonStyle}
                  disabled={props.disabled}
                >
                  EXIT
                </Button>
              </Grid>
              {!isAdmin && (
                <Grid item xs={12} md={4} lg={3} container>
                  <DisableMonitoringButton
                    buttonLabel="DISABLE MONITORING"
                    dialogTitle="Disable Continuous Monitoring"
                    dialogContent={
                      <p>
                        Are you sure you want to disable continuous monitoring
                        for all reports?
                      </p>
                    }
                    onConfirm={pauseContinuousMonitoring}
                  />
                </Grid>
                )}
              <Grid item xs={12} md={4} lg={3} container>
                <Button
                  size="medium"
                  onClick={() => {
                    redirect(
                      isAdmin
                      ? `${RESOURCES_BASEPATH.REPORTS}/${encodeURIComponent('client/' + record['clientId'] + '/detail/' + record['clientRequestId'])}/show` 
                      : `${RESOURCES_BASEPATH.REPORTS}/${record['clientRequestId']}/show`);
                  }}
                  sx={{...baseButtonStyle()}}
                >
                  View Request
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>        
      </List>      
    </Grid>
  </Grid>
  );
}

export const IDListItem = ({ title, value }) => {  
  return (
    <ListItem sx={padding2.y}>
      <Grid container alignItems={'center'}>
        <Grid item xs={12} md={4} sx={textColor.gray}>{title}</Grid>
        <Grid item xs={12} md={8}>
          {value}
        </Grid>
      </Grid>
    </ListItem>
  );
};