import * as React from "react";
import {
  Menu,
  MenuItemLink,
  useResourceDefinitions,
  useSidebarState,
} from "react-admin";
import DefaultIcon from "@mui/icons-material/ViewList";
import { DISABLE_MATTER_TOOLTIP_MESSAGE, ENABLED_FEATURES_ENUM, RESOURCES, DISABLE_WATCHLIST_TOOLTIP_MESSAGE } from "../../Constants";
import { menu } from "../../Styles";
import { Grid, Tooltip } from "@mui/material";
import { isAdminUser, isFeatureEnabled } from "../../DataProvider";

const CustomMenu = () => {
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map((name) => resourcesDefinitions[name]);
  const matterDisabled = !isFeatureEnabled(ENABLED_FEATURES_ENUM.MATTER_ENABLED);
  const watchListViewingDisabled = !(isFeatureEnabled(ENABLED_FEATURES_ENUM.IDENTITY_VIEW_ONLY) || isFeatureEnabled(ENABLED_FEATURES_ENUM.IDENTITY_ENABLED));
  const watchListFullFeatureDisabled = !isFeatureEnabled(ENABLED_FEATURES_ENUM.IDENTITY_ENABLED);
  const isAdmin = isAdminUser();

  return (
    <Menu sx={menu.container}>
      <Grid sx={menu.logoContainer}>
        <img id="menu-logo" src="whiteLogo.png" alt="Armalytix Logo" style={menu.logo} />
      </Grid>
      {resources.map((resource) =>
          resource.name !== RESOURCES.SURVEYS && ( resource.name !== RESOURCES.IDENTITY_VERIFICATION || (resource.name === RESOURCES.IDENTITY_VERIFICATION && (!watchListViewingDisabled || isAdmin)))
          && (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              onClick={(e) => {    
                const matterPreventDefault = resource.name === RESOURCES.MATTERS && !isAdmin && matterDisabled;
                const watchListPreventDefault = resource.name === RESOURCES.IDENTITY_VERIFICATION && !isAdmin && watchListFullFeatureDisabled;
                if(matterPreventDefault || watchListPreventDefault) {
                  e.preventDefault();
                }
              }}
              primaryText={<PrimaryTextForMenu resource={resource} isAdmin={isAdmin} matterDisabled={matterDisabled} watchListFullFeatureDisabled={watchListFullFeatureDisabled} />}
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              sx={menu.link}
              style={(resource.name === RESOURCES.MATTERS && !isAdmin && matterDisabled) || (resource.name === RESOURCES.IDENTITY_VERIFICATION && !isAdmin && watchListFullFeatureDisabled) ? { opacity : "0.38" } : {}}
            />
          )        
      )}
    </Menu>
  );
};

export const PrimaryTextForMenu = (props) => {
  const { resource, matterDisabled, isAdmin, watchListFullFeatureDisabled } = props;
  const [open] = useSidebarState();
  const name = (resource.options && resource.options.label) || resource.name;

  const isDisabledMatter = resource.name === RESOURCES.MATTERS && !isAdmin && matterDisabled;
  const isDisabledWatchlist = resource.name === RESOURCES.IDENTITY_VERIFICATION && !isAdmin && watchListFullFeatureDisabled;
  
  if (isDisabledMatter || isDisabledWatchlist) {
    const message = isDisabledMatter ? DISABLE_MATTER_TOOLTIP_MESSAGE : DISABLE_WATCHLIST_TOOLTIP_MESSAGE;
    return open ? (
      <Tooltip placement="right" title={message}>
        <div>{name}</div>
      </Tooltip>
    ) : (
      <div>{message}</div>
    );
  }
  
  return <div>{name}</div>;
};

export default CustomMenu;
