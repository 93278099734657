import {required,maxLength,regex} from 'react-admin'
import moment from "moment-timezone";
import momentDate from "./moment-date";
import Compressor from "compressorjs";
import {
  DATE_FORMAT,
  DEFAULT_LOCALE,
  TIME_RANGES,
  REPORT_TYPES,
  DISPLAY_NAMES,
  REPORT_TYPES_DISPLAY_NAMES,
  FREQUENCY_TYPES_DISPLAY_NAMES,
  CURRENCY_SYMBOLS,
  TIME_RANGES_STRING,
  FOLLOW_UP_DATE_RANGE_MONTHS,
  FILE_NAME_ALLOWED_LENGTH_FOR_DOWNLOAD,
  TIME_RANGE_INPUT,
  TIME_RANGE_INPUT_TYPES_DISPLAY_NAMES,
  FOLLOW_UP_DATE_RANGE,
  FOLLOW_UP_DATE_RANGE_DISPLAY_NAMES
} from "../components/Constants";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull"

export const findDiffInMonths = (startDate, endDate) => {
  return momentDate(endDate).diff(startDate, "months");
};

export const getPastDateByMonths = (date, month) => {
  return momentDate(date).subtract(month, "months");
}

export const isFutureDate = (date) => {
  return momentDate(date).isAfter(moment().format(DATE_FORMAT));
};

export const isValidUUID = (uuid) => {
  return uuid.match(
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
  )
    ? true
    : false;
};

export const requiredValidation = required("This field is required");

export const validateColor = (value) => {
  if (
    value &&
    (value.charAt(0) !== "#" || value.indexOf(" ") !== -1 || value.length !== 7)
  ) {
    return "Please enter a valid hex color.";
  }
};

export const emailValidation = regex(
  new RegExp("^[a-zA-Z0-9+_.'-]+@[a-zA-Z0-9.-]+$"),
  "Please enter a valid email."
);

export const secondaryEmailValidation = (values, allValues) =>{
  const trimmedEmail = allValues.email && allValues.email.trim();
  const trimmedSecondaryEmail = allValues.secondaryEmail && allValues.secondaryEmail.trim();
   
  if(trimmedEmail === trimmedSecondaryEmail){
    return "The secondary email should be different from the email.";
  }
}

export const followUpDataRangeValidation = (values, allValues) => {
  const followUpDataRange = allValues.followUpDataRange;
  const timeRange = allValues.timeRange;
  const startDate = allValues.startDate;
  const userStartDate = moment().subtract(FOLLOW_UP_DATE_RANGE_MONTHS[followUpDataRange], "months").date(1).format(DATE_FORMAT);

  if(timeRange > FOLLOW_UP_DATE_RANGE_MONTHS[followUpDataRange]) return "The user start date should comes before start date."

  if(timeRange === TIME_RANGES.CUSTOM && userStartDate > startDate) {
    return "The user start date should comes before start date.";
  }

}

export const sameEmailDomainCheck = (userEmail) => {
  const trimmedEmail = userEmail && userEmail.trim();
  const parsedBearerToken = parseBearerToken();
  const clientAdminEmail = parsedBearerToken.email;
  const clientAdminEmailDomain = clientAdminEmail.substring(clientAdminEmail.indexOf("@") + 1);
  const userEmailDomain = trimmedEmail.substring(trimmedEmail.indexOf("@") + 1);
  if(clientAdminEmailDomain !== userEmailDomain){
    return ("User email domain is not same as clients " + clientAdminEmailDomain);
  }
}

export const maxLengthValidation = (length) =>
  maxLength(length, "Length cannot exceed 250 characters.");

export const urlValidation = regex(
  new RegExp(
    "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$"
  ),
  "Please enter valid URL."
);

export const validatePasswordStrength = regex(
  new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"),
  "Password should contain atleast one uppercase, lowercase character, a symbol, a digit and should have a minimum length of 8."
);

export const validateCurrentPassword = (value, allValues) => {
  if (
    isEmpty(value) &&
    !isEmpty(allValues.newPassword)
  ) {
    return "Current password is required when updating your password";
  }
}

export const getMonthsBetween = (startDate, endDate) => {
  return startDate != null && endDate != null
    ? momentDate(endDate).diff(momentDate(startDate), "months", true)
    : null;
};

export const getParsedDate = (date) => {
  //showing london local time for all the screens
  return moment.tz(date, DEFAULT_LOCALE).format("DD/MM/YY HH:mm");
};

export const getYesterday = () => {
  return moment().tz(DEFAULT_LOCALE).subtract(1, 'days').format("YYYY-MM-DDTHH:mm:ss[Z]");
}

export const parseDateForExport = (date) => {
  return date != null
    ? moment(date, 'YYYY-MM-DD HH:mm:ss A').format("YYYY-MM-DD HH:mm:ss")
    : null;
}

export const getDateComponent = (date) => {
  return date != null
    ? moment.tz(date, DEFAULT_LOCALE).format("DD/MM/YYYY")
    : null;
};

export const parseDateToDateAndTimeString = (date) => {
  //showing london local time for all the screens
  return moment.tz(JSON.parse(JSON.stringify(date)), DEFAULT_LOCALE).format("DD/MM/YYYY HH:mm");
};

export const calculateStartDate = (timeRange, startDate, reportType) => {
  let dateString = startDate;
  if (timeRange && timeRange !== TIME_RANGES.CUSTOM) {
    dateString = moment().subtract(timeRange, "months").format(DATE_FORMAT);
    const date = new Date(dateString);
    if (
      shouldDateBeStartOfMonth(reportType) &&
      dateString !== '01'
    ) {
      date.setDate(1);
      dateString = momentDate(date.toString()).format(DATE_FORMAT);
    }
  }

  return formatStartDate(dateString);
};

export const formatStartDate = (startDate) => {
  return moment.tz(startDate, DEFAULT_LOCALE).startOf('day').format();
};

export const calculateStartDateUsingMidDate = (timeRange, midDate, reportStartDate) => {
  return momentDate(
    moment.max(moment(reportStartDate).startOf('month'), moment(midDate).subtract(FOLLOW_UP_DATE_RANGE_MONTHS[timeRange], 'M').startOf('month'))
  ).format("YYYY-MM-DD");
};

export const calculateEndDateUsingMidDate = (timeRange, midDate, reportEndDate) => {
  return momentDate(
    moment.min(moment(reportEndDate).endOf('month'), moment(midDate).add(FOLLOW_UP_DATE_RANGE_MONTHS[timeRange], 'M').endOf('month'))
  ).format("YYYY-MM-DD");
};

export const shouldDateBeStartOfMonth = (reportType) => {
  return(
    reportType === REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS ||
    reportType === REPORT_TYPES.TRANSACTION_ANALYSIS ||
    reportType === REPORT_TYPES.CLAIMS_REVIEW ||
    reportType === REPORT_TYPES.INCOME ||
    reportType === REPORT_TYPES.AFFORDABILITY ||
	  reportType === REPORT_TYPES.DETAILED_AFFORDABILITY 
  );
}

export const calculateUserStartDate = (followUpDataRange) => {
  if (isEmpty(followUpDataRange)) return null;

  let dateString = moment().subtract(FOLLOW_UP_DATE_RANGE_MONTHS[followUpDataRange], "months").date(1).format(DATE_FORMAT);
  return moment.tz(dateString + " 00:00", DEFAULT_LOCALE).format();
}

export const parseEndDate = (timeRange, endDate) => {
  //non custom date range or custom date range with default date, send end date as null to backend
  if (timeRange &&
    (timeRange !== TIME_RANGES.CUSTOM ||
    (timeRange === TIME_RANGES.CUSTOM &&
      endDate === moment().format(DATE_FORMAT)))
  )
    return null;
  else return formatEndDate(endDate);
};

export const formatEndDate = (endDate) => {
  return moment.tz(endDate, DEFAULT_LOCALE).endOf('day').format();
};

export const getCreationMessage = (resource, isEdit, data) => {
  if (
    data &&
    data.parsedRequestBody &&
    data.parsedRequestBody.scheduleRequest &&
    !isEdit
  )
    return "Schedule Created";

  return isEdit
    ? DISPLAY_NAMES[resource] + " Updated"
    : DISPLAY_NAMES[resource] + " Created";
};

export const isTransactionalReport = (reportType) => {
  return [
    REPORT_TYPES.SOURCE_OF_FUNDS_DATA,
    REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS,
    REPORT_TYPES.TRANSACTION_ANALYSIS,
    REPORT_TYPES.CLAIMS_REVIEW,
    REPORT_TYPES.TRANSACTION_DOWNLOAD,
    REPORT_TYPES.INCOME,
    REPORT_TYPES.AFFORDABILITY,
    REPORT_TYPES.DETAILED_AFFORDABILITY,
    ].includes(reportType)
}

export const isIncomeReport = (reportType) => {
  return [
    REPORT_TYPES.INCOME,
    REPORT_TYPES.AFFORDABILITY,
    REPORT_TYPES.DETAILED_AFFORDABILITY,
    ].includes(reportType)
}

export const positiveValue = (value) => {
  if (value < 0) {
    return 'Please enter a valid value.';
  }
};

export const positiveAmount = (amount) => {
  if (amount < 0) {
    return 'Please enter a valid amount.';
  }
};

 export const createReportChoicesDynamically = (reports) => {
  let reportTypeChoices = [];
  reports.forEach((report) => {
    let choice = {};
    choice.id = report;
    choice.name = REPORT_TYPES_DISPLAY_NAMES[report];
    reportTypeChoices.push(choice);
  });
  return reportTypeChoices;
};

export const createFrequencyChoicesDynamically = (options) => {
  let frequencyTypeChoices = [];
  options.forEach((option) => {
    let choice = {};
    choice.id = option;
    choice.name = FREQUENCY_TYPES_DISPLAY_NAMES[option];
    frequencyTypeChoices.push(choice);
  });
  return frequencyTypeChoices;
};

export const createCurrencyChoicesDynamically = (options) => {
  let currencyTypeChoices = [];
  options.forEach((option) => {
    let choice = {};
    choice.id = option;
    choice.name = CURRENCY_SYMBOLS[option];
    currencyTypeChoices.push(choice);
  });
  return currencyTypeChoices;
};

export const createSurveyChoicesDynamically = (surveys, options) => {
  // return back only the surveys allowed for this template
  return surveys.filter((item) => options.includes(item.name));
};

export const createTimeRangeOptionsDynamically = (choices) => {
  let options = [];
  choices.forEach(choice => {
    let option = {
      id: TIME_RANGE_INPUT[choice],
      name: TIME_RANGE_INPUT_TYPES_DISPLAY_NAMES[TIME_RANGE_INPUT[choice]]
    };
    options.push(option);
  })
  return options;
};

export const getChoicesForTimeRange = (reportType) => {
  let choices = [
    { id: '3', name: TIME_RANGES_STRING.MONTHS_3 },
    { id: '6', name: TIME_RANGES_STRING.MONTHS_6 },
    { id: '12', name: TIME_RANGES_STRING.YEAR_1 },
    { id: '24', name: TIME_RANGES_STRING.YEARS_2 },
    { id: '120', name: TIME_RANGES_STRING.MAX },
    { id: 'CUSTOM', name: TIME_RANGES_STRING.CUSTOM },
  ];

  return choices;
};

export const createTimeRangeChoicesDynamically = (choices) => {
  let options = []
  choices.forEach(choice => {
    let option = {
      id: TIME_RANGES[choice],
      name: TIME_RANGES_STRING[choice]
    }
    options.push(option)
  })
  return options
};

export const createFollowUpTimeRangeChoicesDynamically = (choices) => {
  let options = []
  choices.forEach(choice => {
    let option = {
      id: FOLLOW_UP_DATE_RANGE[choice],
      name: FOLLOW_UP_DATE_RANGE_DISPLAY_NAMES[choice]
    }
    options.push(option)
  })
  return options
};

export const generateRandomPassword = () => {
  
  const keys = {
    upperCase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    lowerCase: "abcdefghijklmnopqrstuvwxyz",
    number: "0123456789",
    symbol: "!@#$%^&*()_+~`|}{[]:;?><,./-="
  };

  const getKey = [
    function upperCase() {
      return keys.upperCase[Math.floor(Math.random() * keys.upperCase.length)];
    },
    function lowerCase() {
      return keys.lowerCase[Math.floor(Math.random() * keys.lowerCase.length)];
    },
    function number() {
      return keys.number[Math.floor(Math.random() * keys.number.length)];
    },
    function symbol() {
      return keys.symbol[Math.floor(Math.random() * keys.symbol.length)];
    }
  ];

  let password = "";

  while (8 > password.length) { //To create password of length atleast 8
    password += getKey[Math.floor(Math.random() * getKey.length)]();         
  }

  for( let i=0; i<getKey.length; i++ ){ //To make sure that it contain atleast one uppercase, lowercase, number and special character.
    password += getKey[i]();
  }

  return password;
}

export const compressImage = async (
  file,
  quality,
  convertSize
  ) => {
  return await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      convertSize,
      success: resolve,
      error: reject,
    });
  });
};

function parseBearerToken () {
  const token = localStorage.getItem("auth")
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function filterNullValuesFromList (list) {
  list = list.filter(e => e !== null);
  return list;
}

export const emailWithSpacesValidation = (value) => {

  const emailRegExp = new RegExp("^[a-zA-Z0-9+_.'-]+@[a-zA-Z0-9.-]+$");
  const emailWithSpacesRegExp = new RegExp("^[\\s]+[a-zA-Z0-9+_.'-]+@[a-zA-Z0-9.-]+[\\s]+$");  
  const emailWithSpacesatBeginRegExp = new RegExp("^[\\s]+[a-zA-Z0-9+_.'-]+@[a-zA-Z0-9.-]+$");  
  const emailWithSpacesatEndRegExp = new RegExp("^[a-zA-Z0-9+_.'-]+@[a-zA-Z0-9.-]+[\\s]+$");
  
  const isValidEmail = 
          emailRegExp.test(value) || 
          emailWithSpacesatBeginRegExp.test(value) || 
          emailWithSpacesatEndRegExp.test(value) || 
          emailWithSpacesRegExp.test(value);

  if(!isValidEmail){
    return "Please enter a valid email.";
  }  

}

export const secondaryEmailWithSpacesValidation = (value) => {  
  if(!isEmpty(value) && !isEmpty(value.trim())){
    return emailWithSpacesValidation(value);
  }
}

export const createErrorObject = (errorCode, errorDescription) => {
  return {
    body : {
      errors : [{
        code : errorCode,
        description : errorDescription,                
      }]
    }
  };  
}

export const avoidApiCallForEmptyValues = () => {
  return Promise.resolve().then((val) => {
    return {
      data : [],
      total: 0,                
    }
  })  
}

export const secondReportLengthValidation = (value, allValues) => {

  const reportStartDate = moment().subtract(FOLLOW_UP_DATE_RANGE_MONTHS[value], "months").date(1);

  if(moment(allValues.userStartDate, DATE_FORMAT).isAfter(reportStartDate)) {
    return "The Report length should be in range of start date.";
  }
}

export const secondReportStartDateValidation = (value, allValues) => {

  if(moment(allValues.reportStartDate, DATE_FORMAT).isAfter(value)) {
    return "The Report start date cannot be before user start date of original report.";
  }

  if(!moment(value).isSame(moment(value).startOf('month'))) {
    return "The Report start date must be start of month.";
  }
}

export const secondReportMidDateValidation = (value, allValues) => {

  if(moment(allValues.reportStartDate, DATE_FORMAT).isAfter(value)) {
    return "The Report mid date cannot be before user start date of original report.";
  }
}

export const secondReportEndDateValidation = (value, allValues) => {

  if(moment(value).isAfter(moment(allValues.reportEndDate).endOf('month'))) {
    return "The Report end date should be in range of original report end date.";
  }

  if(moment(allValues.startDate).isAfter(value)) {
    return "The Report end date should be after start date.";
  }
}

export const trimFileNameForDownload = (fileName) => {
  if(fileName.length > FILE_NAME_ALLOWED_LENGTH_FOR_DOWNLOAD){
    return fileName.substring(0, FILE_NAME_ALLOWED_LENGTH_FOR_DOWNLOAD);
  }else{
    return fileName;
  }
}

export const validate = required("This is a mandatory field.");

export const deleteCallback = (resource, callbackType, notify, redirect, basePath) => {
  if (callbackType === 'success')
    notify(`${resource} Deleted`, { type: 'info', autoHideDuration: 1000 });
  else if (callbackType === 'failure')
    notify(`Unable to delete the ${resource}`, { type: 'warning', autoHideDuration: 1000 });

  if (redirect)
    redirect(basePath);
};

export const generateDurationStringFromDays = (days) => {
  const duration = moment.duration({ days: days });
  const durationString = duration.toISOString();
  return durationString;
}

export const calculateDaysFromDuration = (durationString) => {  
  const duration = moment.duration(durationString);
  return duration.asDays();
}

export const isFalsy = (value) => {
  return (isNull(value) ||
    isUndefined(value) ||
    value === '' );
}

export const dateRoundedToTheClosestMinutes = (minutesToRoundTo) => {
  return Math.round(moment().minute() / minutesToRoundTo) * minutesToRoundTo;
}

export const dateRoundedDownToTheClosestMinutes = (minutesToRoundTo) => {
  return Math.floor(moment().minute() / minutesToRoundTo) * minutesToRoundTo;
}

export const dateRoundedUpToTheClosestMinutes = (minutesToRoundTo) => {
  return Math.ceil(moment().minute() / minutesToRoundTo) * minutesToRoundTo;
}

export const roundedTimeByMinutes = (minutesToRoundTo) => {
  return moment().minute(dateRoundedDownToTheClosestMinutes(minutesToRoundTo)).second(0)
}

export const isPdfEnabled = (reportType) => {
  return [
    REPORT_TYPES.ACCOUNT_VERIFICATION,
    REPORT_TYPES.ACCOUNT_VERIFICATION_PLUS,
    REPORT_TYPES.PROOF_OF_FUNDS,
    REPORT_TYPES.SOURCE_OF_FUNDS_DATA,
    REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS,
    REPORT_TYPES.TRANSACTION_ANALYSIS,
    REPORT_TYPES.TRANSACTION_DOWNLOAD,
    REPORT_TYPES.SURVEY_ONLY,
    REPORT_TYPES.INCOME,
    REPORT_TYPES.AFFORDABILITY,
    REPORT_TYPES.DETAILED_AFFORDABILITY,
    REPORT_TYPES.IDENTITY_VERIFICATION,
  ].includes(reportType);
}

export const isCsvEnabled = (reportType) => {
  return [
    REPORT_TYPES.SOURCE_OF_FUNDS_DATA,
    REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS,
    REPORT_TYPES.TRANSACTION_ANALYSIS,
    REPORT_TYPES.TRANSACTION_DOWNLOAD,
    REPORT_TYPES.DETAILED_AFFORDABILITY,
  ].includes(reportType);
}

export const isCsvMultiFormatEnabled = (reportType) => {
  return [REPORT_TYPES.TRANSACTION_DOWNLOAD].includes(
    reportType
  );
}

export const isExcelEnabled = (reportType) => {
  return [
    REPORT_TYPES.TRANSACTION_ANALYSIS,
    REPORT_TYPES.DETAILED_AFFORDABILITY,
    REPORT_TYPES.CLAIMS_REVIEW
  ].includes(reportType);
}

export const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data))
}

export function parseUserNameChoicesForAdmins(data) {
  const reqUserNameList = [];
  for (const [key, value] of [...data.entries()].sort((e1, e2) => e1[1].localeCompare(e2[1]))) {
      reqUserNameList.push({
          id: key,
          name: value
      });
  }
  return reqUserNameList;
}

export function isPersonaTemplateEnabled(reportType) {  
  return [REPORT_TYPES.IDENTITY_VERIFICATION].includes(reportType);
}
