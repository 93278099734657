import React, {useState} from "react";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import PdfPreview from "./PdfPreview";
import CircularProgress from '@mui/material/CircularProgress';
import {
  IDENTITY_VERIFICATION_REQUEST_STATUS_KEYS,
  REPORT_FORMATS,
  REPORT_STATUSES,
  RESOURCES,
} from "../components/Constants";
import { httpClient, isAdminUser } from "../components/DataProvider";
import {iconAction,linkButton} from '../components/Styles'
import {Button, isEmpty} from 'react-admin'
import {default as MuiButton} from "@mui/material/Button";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {Grid} from '@mui/material'
import isNull from "lodash/isNull"
import { createErrorObject } from "./util";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

function PreviewLink(props) {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [doc, setDoc] = useState({});
    const { clientRequestId, clientId,  status, resource } = props;
  
    let reportUrl = isAdminUser()
      ? `${apiUrl}/admin/report-request/client/${clientId}/report-data/${clientRequestId}?format=${REPORT_FORMATS.PDF}`
      : `${apiUrl}/api/report-request/${clientRequestId}?format=${REPORT_FORMATS.PDF}`;    
        
    const handleClick = async () => {
      setLoading(true);
      setShowModal(true);
      await httpClient(reportUrl).then(({ json }) => {
        if(isNull(json.data) || !isEmpty(json.errors)){
          throw createErrorObject(json.errors[0].code, json.errors[0].description);
        }
        const urlWithBase64Data = json.data.reportData.formattedReportData;
        setDoc({ url: `data:application/pdf;base64,${urlWithBase64Data}` });
        setLoading(false);
      });
    };

    const closeModal = () => {
      setShowModal(false);
    };
    const isReportAvailable = ((status === REPORT_STATUSES.AVAILABLE) || (resource === RESOURCES.IDENTITY_VERIFICATION && status === IDENTITY_VERIFICATION_REQUEST_STATUS_KEYS.COMPLETED));    
    return (isReportAvailable ? (
      <React.Fragment>
        <MuiButton
          sx={linkButton}
          size="medium"
          disabled={loading}
          onClick={handleClick}
        >
          Preview
        </MuiButton>
        <Dialog onClose={closeModal} scroll="paper" maxWidth="xl" aria-labelledby="customized-dialog-title" open={showModal}>
          <DialogTitle display="flex" justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={closeModal}
                sx={iconAction}
                size="large"
              >
                <CloseOutlinedIcon />
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            {
              loading ?
                <Grid container justifyContent="center">
                  <CircularProgress color="inherit" />
                </Grid> :
                <PdfPreview file={doc} />
            }
          </DialogContent>
        </Dialog>
      </React.Fragment> ) : "--"
    );
};
export default PreviewLink;