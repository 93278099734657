import { isEmpty } from "ra-core";
import { SCREENING_REPORT_TYPES } from "../components/Constants";
import { getParsedDate, parseDateForExport } from "../utils/util";
import { isAdminUser } from "../components/DataProvider";

export const parseIdReportsData = (data) => {
  if (!data) return {};

  const {
    id,
    createdAt,
    updatedAt,
    identityRequestStatus,        
    clientId,
    screeningReportDtoList = [],
    userId,
    continuousMonitoringEnabled,
    hasUnAcknowledgedMatch,
    reportRequestId,
    requestingUserId,
    screeningMatchStatus,
  } = data;
  
  const { userData = {}, clientRequestId, clientReference, reportParams } = data?.reportRequestParamsJson !== null ? JSON.parse(data?.reportRequestParamsJson) : {};
  const lastModified = getParsedDate(updatedAt);
  const created = getParsedDate(createdAt);
  const name = userData?.accountName;
  const emailAddress = userData?.emailAddress;
  const isPEPReportPresent = isScreeningReportPresent(screeningReportDtoList, SCREENING_REPORT_TYPES.POLITICALLY_EXPOSED_PERSON);
  const isWatchListReportPresent = isScreeningReportPresent(screeningReportDtoList, SCREENING_REPORT_TYPES.WATCHLIST);
  const isAdverseMediaReportPresent = isScreeningReportPresent(screeningReportDtoList, SCREENING_REPORT_TYPES.ADVERSE_MEDIA);

  return {
    id : isAdminUser() ? 'client/' + clientId + '/identity-verification/' + id : id,    
    name,
    emailAddress,
    createdAt: created,
    updatedAt: lastModified,
    identityRequestStatus,
    clientRequestId,
    clientId,
    clientReference,
    isPEPReportPresent,
    isWatchListReportPresent,
    isAdverseMediaReportPresent,
    userId,
    continuousMonitoringEnabled,
    hasUnAcknowledgedMatch,
    reportRequestId,
    requestingUserId,
    businessName: reportParams ? reportParams.businessName : '',
    descriptionOfMatter: reportParams ? reportParams.descriptionOfMatter : '',
    screeningMatchStatus,
  };
};

export const parseIdRequest = (data) => {
  if (!data) return {};

  const { 
    id, 
    identityRequestStatus, 
    createdAt, 
    updatedAt, 
    reportRequestParamsJson, 
    clientId, 
    screeningReportDtoList = [], 
    reportRequestId, 
    continuousMonitoringEnabled, 
    hasUnAcknowledgedMatch,
    screeningMatchStatus, 
  } = data;
    
  const created = getParsedDate(createdAt);
  const lastModified = getParsedDate(updatedAt);
  const requestParams = JSON.parse(reportRequestParamsJson);
  const templateId = requestParams?.reportParams?.identityVerificationTemplateId;
  const name = requestParams?.userData?.accountName;
  const emailAddress = requestParams?.userData?.emailAddress;
  const { clientReference, clientRequestId, reportParams } = requestParams || {};

  const pepReport = getReportData(screeningReportDtoList, SCREENING_REPORT_TYPES.POLITICALLY_EXPOSED_PERSON);
  const watchlistReport = getReportData(screeningReportDtoList, SCREENING_REPORT_TYPES.WATCHLIST);
  const adverseMediaReport = getReportData(screeningReportDtoList, SCREENING_REPORT_TYPES.ADVERSE_MEDIA);

  return {
    id : isAdminUser() ? 'client/' + clientId + '/identity-verification/' + id : id,
    identityRequestStatus,
    created,
    lastModified,
    templateId,
    name,
    emailAddress,
    clientReference,
    clientId,
    clientRequestId,
    pepReportId: pepReport.id,
    watchlistReportId: watchlistReport.id,
    adverseMediaReportId: adverseMediaReport.id,
    reportRequestId,
    continuousMonitoringEnabled,
    hasUnAcknowledgedMatch,
    pepReportMatch: pepReport.screeningMatchStatus,
    watchlistReportMatch: watchlistReport.screeningMatchStatus,
    adverseMediaReportMatch: adverseMediaReport.screeningMatchStatus,
    businessName: reportParams ? reportParams.businessName : '',
    descriptionOfMatter: reportParams ? reportParams.descriptionOfMatter : '',
    screeningMatchStatus,
  };
}

export const parseScreeningReport = (data) => {  
  if (!data) return {};
  
  const { 
    id, 
    continuousMonitoringEnabled, 
    hasMatch, 
    reportType, 
    updatedAt, 
    events = [],
    createdAt,
    screeningMatchStatus,     
  } = data;

  return {
    id,
    reportType,
    hasMatch,
    continuousMonitoringEnabled,
    lastModified: getParsedDate(updatedAt),
    created: getParsedDate(createdAt),
    events: parseEvents(events),
    screeningMatchStatus,
  };
}

const parseEvents = (events) => {
  if (!Array.isArray(events)) return [];
  
  return events.map(event => {
    const { 
      id, 
      birthRecord, 
      name, 
      reviewedBy, 
      reviewedAt, 
      screeningReportId, 
      sources, 
      status, 
      summaries, 
      createdAt, 
      updatedAt 
    } = event;

    return {
      id,
      birthRecord: !isEmpty(birthRecord) ? birthRecord : "",
      name,
      reviewedBy,
      reviewedAt: !isEmpty(reviewedAt) ? getParsedDate(reviewedAt) : "",
      screeningReportId,
      sources,
      status,
      summaries,
      created: getParsedDate(createdAt),
      lastModified: getParsedDate(updatedAt),
    };
  });
}

const isScreeningReportPresent = (screeningReportDtoList, screeningReportType) => {
  if (!Array.isArray(screeningReportDtoList)) return false;
  return screeningReportDtoList.filter(report => report.reportType === screeningReportType).length === 1;  
}

const getScreeningReport = (screeningReportDtoList, screeningReportType) => {
  if (!Array.isArray(screeningReportDtoList)) return [];
  return screeningReportDtoList.filter(report => report.reportType === screeningReportType);  
}

const getReportData = (screeningReportDtoList, reportType) => {
  const report = getScreeningReport(screeningReportDtoList, reportType)?.[0];
  return {
    id: report?.id,
    hasUnAcknowledgedMatch: report?.hasUnAcknowledgedMatch,
    screeningMatchStatus: report?.screeningMatchStatus,
  };
};

export const parseIdRecordsforExport = (records, data) => {  
  return records.map((record) => {             
      return parseIdReportForExport(record, data);
  })
}

const parseIdReportForExport = ( record, reqUserName ) => {

  const {
    id,
    clientRequestId,
    name,
    emailAddress,
    identityRequestStatus,
    continuousMonitoringEnabled,
    hasUnAcknowledgedMatch,
    createdAt,
    updatedAt,
    requestingUserId,
  } = record;        

  const data = {
    id,
    clientRequestId,        
    name,
    emailAddress,
    identityRequestStatus,
    continuousMonitoringEnabled,
    hasUnAcknowledgedMatch,
    created : parseDateForExport(createdAt),
    lastModified : parseDateForExport(updatedAt),
    requestingUserName : reqUserName && reqUserName.get(requestingUserId) != null ? reqUserName.get(requestingUserId) : ' ',        
  };

  return data;
}