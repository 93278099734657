import * as React from "react";
import { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ShowButton,
  FunctionField,
} from 'react-admin'
import { ActionTitles, SCHEDULE_STATUS, RESOURCES_BASEPATH } from "../Constants";
import { isAdminUser } from "../DataProvider";
import ListActions from "../common/ListActions";
import { ReminderIcon, ReminderOffIcon } from "../common/StyledIcons";
import { CustomPagination } from "../common/CustomPaginationComponent";
import {cellContentCentered, dataGrid, iconAction, listRoot} from '../Styles'
import {CustomDataGridHeader, onToggleDataGridHeader} from '../common/CustomDataGridHeader'
import defaultsDeep from 'lodash/defaultsDeep';
import cloneDeep from 'lodash/cloneDeep';
import CustomTooltip from '../../utils/CustomTooltip'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import {SchedulesAdminFilter,SchedulesFilter} from './SchedulesFilters'

export const Schedules = () => {
  const isAdmin = isAdminUser();
  let dataGridsLS = JSON.parse(localStorage.getItem("dataGrids"))
  if (!dataGridsLS)
    dataGridsLS = { "schedules": true }
  const [dataGrids, setDataGrids] = useState(dataGridsLS ? dataGridsLS : {});

  return (
    <List
      sx={listRoot}
      sort={{ field: "lastModified", order: "DESC" }}
      actions={
        <ListActions
          title={ActionTitles.SCHEDULES}
          showCreateButton={true}
          basePath={RESOURCES_BASEPATH.REPORTS}
          filters={isAdmin ? <SchedulesAdminFilter /> : <SchedulesFilter />}
        />
      }
      filterDefaultValues={{ scheduleStatus : SCHEDULE_STATUS.ACTIVE }}      
      exporter={false}
      empty={false}
      pagination={<CustomPagination />}
    >
      <Datagrid
        header={
          <CustomDataGridHeader
            fixedColumn={dataGrids['schedules']}
            onToggle={() => onToggleDataGridHeader('schedules', dataGrids, setDataGrids)}
          />
        }
        sx={
          defaultsDeep(
            cloneDeep(dataGrid.base),
            dataGrids['schedules'] ? cloneDeep(dataGrid.firstColSticky) : {},
          )
        }
        bulkActionButtons={false}
      >
        <TextField source="contactName" label="CONTACT NAME" sortable={false} />
        <TextField source="businessName" label="BUSINESS NAME" sortable={false} />
        <TextField source="descriptionOfMatter" label="DESCRIPTION OF MATTER" sortable={false} />
        <TextField source="clientReference" label="CLIENT REFERENCE" sortable={false} />
        <TextField source="emailAddress" label="EMAIL ADDRESS" sortable={false} />
        <TextField source="reportType" label="REPORT TYPE" sortable={false} />
        <TextField source="frequency" label="FREQUENCY" />
        <TextField source="startDate" label="START DATE" sortable={false}/>
        <BooleanField
          source="reminders"
          label="REMINDERS"
          sx={cellContentCentered}
          TrueIcon={ReminderIcon}
          FalseIcon={ReminderOffIcon}
          sortable={false}
        />
        <TextField source="status" label="STATUS" />
        <FunctionField
          sx={cellContentCentered}
          source="View"
          label={false}
          sortable={false}
          render={(record) => (
            <CustomTooltip title="View" placement="top">
              <ShowButton
                icon={<RemoveRedEyeOutlinedIcon />}
                sx={iconAction}
                size="medium"
                label={""}
                basePath={RESOURCES_BASEPATH.SCHEDULES}
                record={record}
              ></ShowButton>
            </CustomTooltip>
          )}
        />
      </Datagrid>
    </List>
  );
};
